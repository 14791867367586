<template>
  <li class="list-group-item">
    <div class="d-flex">
      <div class="avatar">
        <i class="fas fa-cubes fa-lg text-muted"></i>
      </div>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class>
          <h4 class="mb-0">{{item.name}}</h4>
          <p class="text-muted">{{item.description}}</p>
        </div>
        <base-dropdown
          v-if="rule && rule.canUpdate"
          tag="div"
          title-tag="a"
          class="nav-item fiut"
          menu-classes="dropdown-navbar"
        >
          <a
            slot="title"
            href="javascript:void(0)"
            class="dropdown-toggle nav-link"
            data-toggle="dropdown"
            aria-expanded="true"
          >
            <div class="photo">
              <i class="fas fa-ellipsis-v text-muted"></i>
            </div>
          </a>
          <li class="nav-link">
            <button type="button" class="btn btn-link" @click="editItem(item)">
              <i class="fas fa-info-circle fa-lg mr-2"></i>
              <span>Editar</span>
            </button>
          </li>

          <li class="nav-link">
            <button type="button" class="btn btn-link text-danger" @click="deleteItem(item)">
              <i class="fas fa-info-circle fa-lg mr-2"></i>
              <span>Eliminar</span>
            </button>
          </li>
        </base-dropdown>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    rule: {
      type: Object,
      default: null
    }
  },
  components: {},
  methods: {
    editItem(item) {
      this.$emit("onEdit", item);
    },
    deleteItem(item) {
      this.$emit("onDelete", item);
    }
  }
};
</script>
