<template>
  <form class="mt" @submit.prevent="onSubmit">
    <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{errorMessage}}</b-alert>
    <div class="form-group" v-if="item && item.id">
      <label for="id">ID: {{item.id}}</label>
    </div>
    <div class="form-group">
      <label for="name">Rol</label>
      <input
        class="form-control"
        type="text"
        name="name"
        id="name"
        placeholder="Severidad"
        v-validate="'required'"
        v-model="item.name"
      />
    </div>
    <div class="form-group">
      <label for="description">Descripción</label>
      <textarea
        name="description"
        id="description"
        rows="5"
        class="form-control"
        placeholder="Descripción"
        v-validate="'required'"
        v-model="item.description"
      ></textarea>
    </div>
    <div class="clearfix">
      <div class="btn-toolbar float-right pb-4">
        <b-button
          type="submit"
          size="sm"
          variant="inverse"
          :disabled="!isFormValid || s_loading"
        >Guardar</b-button>
      </div>
    </div>
  </form>
</template>

<script>
import BaseComponent from "@/pages/components/BaseComponent";
export default {
  extends: BaseComponent,
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      errorMessage: ""
    };
  },
  computed: {
    isFormValid() {
      return !Object.keys(this.veeFields).some(
        key => this.veeFields[key].invalid
      );
    }
  },
  methods: {
    onSubmit() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.item.tagName = "N/A";
          this.axioPost({
            url: "roles",
            value: this.item,
            routeTo: "roles"
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
