<template>
  <li class="list-group-item">
    <div class="ticket-body">
      <div class="ticket-content">
        <div class="content-inner pl-0">
          <div class="folio">
            <span class="badge border text-muted p-2 mr-2">{{
              item.folio
            }}</span>
          </div>
          <div class="heading w-100 pointer pr-2" @click="goDetail(item)">
            <h4 class="mb-1">{{ item.title }}</h4>
            <p class="text-muted">{{ item.description }}</p>
          </div>
        </div>
        <div class="row no-gutters footer-x border-top">
          <div class="col-2" :class="{ 'col-4': !ticketId }">
            <template v-if="!ticketId">
              <div class="d-flex align-items-center border-right pl-2 h-100">
                <i class="fas fa-stop fa-lg text-light mr-2"></i>
                <div>
                  <small class="text-muted">Categorización </small>
                  <p class="mb-0">{{ getSeverityName(item.severityId) }}</p>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="d-flex align-items-center border-right pl-2 h-100"
                v-if="roleName && roleName !== 'Administrador'"
              >
                <i class="fas fa-stop fa-lg text-light mr-2"></i>
                <div style="overflow: hidden">
                  <small class="text-muted">Categorización </small>
                  <p class="mb-0">{{ getSeverityName(item.severityId) }}</p>
                </div>
              </div>

              <base-dropdown
                v-else
                tag="div"
                title-tag="a"
                class="nav-item ticket-item mt-1"
                menu-classes="dropdown-navbar "
              >
                <a
                  slot="title"
                  href="javascript:void(0)"
                  class="dropdown-toggle nav-link"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <div class="photo d-flex align-items-center">
                    <i class="fas fa-stop fa-lg mr-2"></i>
                    <div>
                      <small class="text-muted">Categorización </small>
                      <p class="mb-0">{{ getSeverityName(item.severityId) }}</p>
                    </div>
                  </div>
                </a>
                <li
                  class="nav-link"
                  v-for="(sev, index) in severities"
                  :key="index"
                >
                  <button
                    type="button"
                    class="btn btn-link"
                    @click="updateSeverity(item.id, sev.id)"
                  >
                    <span>{{ sev.name }}</span>
                  </button>
                </li>
              </base-dropdown>
            </template>
          </div>
          <div class="col-3" :class="{ 'col-4': !ticketId }">
            <div
              class="d-flex align-items-center border-left border-right h-100"
            >
              <i class="fas fa-circle fa-lg mx-2 text-light"></i>
              <div style="overflow: hidden">
                <small class="text-muted">Estatus</small>
                <p class="mb-0">{{ getStatusName(item.ticketStatusId) }}</p>
              </div>
            </div>
          </div>
          <div class="col-2" :class="{ 'col-4': !ticketId }">
            <div class="d-flex align-items-center h-100">
              <i class="far fa-calendar-alt fa-lg mx-2 text-light"></i>
              <div style="overflow: hidden">
                <small class="text-muted">Fecha</small>
                <p class="mb-0">{{ item.created | date }}</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-5" v-if="ticketId">
            <div
              class="d-flex align-items-center justify-content-end border-left"
            >
              <base-button
                v-if="validateReject(item.ticketStatusId)"
                native-type="button"
                type="primary"
                class="btn-neutral btn-twitter mx-1"
                size="sm"
                :loading="rj_loading"
                @click="rejectAsync"
                >Rechazar</base-button
              >
              <base-button
                v-if="validateCancel(item.ticketStatusId)"
                native-type="button"
                type="primary"
                class="btn-neutral btn-twitter mx-1"
                size="sm"
                :loading="cc_loading"
                @click="cancelAsync"
                >Cancelar</base-button
              >
              <base-button
                v-if="validateReset(item.ticketStatusId)"
                native-type="button"
                type="primary"
                class="btn-neutral btn-twitter mx-1"
                size="sm"
                :loading="rs_loading"
                @click="resetAsync"
                >No Resuelto</base-button
              >
              <base-button
                v-if="validateCloseTicket(item.ticketStatusId)"
                native-type="button"
                type="primary"
                class="btn-neutral btn-twitter mx-1"
                size="sm"
                :loading="cta_loading"
                @click="closeTicketAsync"
                >Cerrar</base-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapState, mapActions } from "vuex";
import API from "@/services/Api";
import BaseComponent from "@/pages/components/BaseComponent";
export default {
  extends: BaseComponent,
  props: {
    item: {
      type: Object,
      default: null,
    },
    status: {
      type: Array,
    },
    rule: {
      type: Object,
      default: null,
    },
  },
  components: { Multiselect },
  data() {
    return {
      ticketStatus: [],
      severities: [],
      user: null,
      ticketId: 0,
      severityId: 0,
      sev_loading: false,
      rj_loading: false,
      cc_loading: false,
      rs_loading: false,
      cta_loading: false,
    };
  },
  created() {
    this.getUserData();
    this.$store.dispatch("loadOptions");
  },
  mounted() {
    this.ticketId = this.$route.params.id;
    this.getSeverities();
    //console.log("rule:", this.rule);
  },
  computed: {
    ...mapState({
      assignedUsers: (state) => state.ticket.assignedUsers,
      options: (state) => state.ticket.options,
    }),
    roleName() {
      const roleName = this.$store.state.roleName;
      return this.user ? this.user.roleName : roleName;
    },
  },
  methods: {
    ...mapActions(["updateValueAction"]),
    getSeverities() {
      API.get("Severity/GetAllAsync")
        .then((response) => {
          this.severities = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getSeverityName(sevetityId) {
      const severity = this.severities.find((x) => x.id == sevetityId);
      return severity ? severity.name : "N/A";
    },
    getStatusName(statusId) {
      const item = this.status.find((x) => x.id == statusId);
      return item ? item.name : "N/A";
    },
    limitText(count) {
      return `+ ${parseInt(count)}`;
    },
    goDetail(item) {
      this.$router.push({ name: "ticket-detail", params: { id: item.id } });
    },
    deleteItem(item) {},
    onSeverityChanged(e) {
      //console.log(e.target.value);
    },
    onStatusChanged(e) {
      //console.log(e.target.value);
    },
    updateSeverity(ticketId, severityId) {
      this.sev_loading = true;
      API.post("Ticket/ChangePriority", {
        id: ticketId,
        severityId: severityId,
      })
        .then((response) => {
          //console.log("res: ", response);
          this.sev_loading = false;
          this.handleResponse(response, true);
        })
        .catch((error) => {
          this.sev_loading = false;
          this.handleFaltalError;
        });
    },
    resetAsync() {
      if (this.item && this.item.id) {
        let formData = new FormData();
        formData.append("id", this.item.id);
        this.rs_loading = true;
        API.post("/Ticket/ResetAsync", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            this.rs_loading = false;
            this.handleResponse(response, true);
          })
          .catch((error) => {
            this.rs_loading = false;
            this.handleFaltalError(error);
          });
      }
    },
    cancelAsync() {
      if (this.item && this.item.id) {
        this.$bvModal
          .msgBoxConfirm("¿Esta seguro de cancelar el ticket?", {
            okTitle: "Cancelar",
            cancelTitle: "No",
          })
          .then((value) => {
            if (value) {
              let formData = new FormData();
              formData.append("id", this.item.id);
              this.cc_loading = true;
              API.post("/Ticket/CancelAsync", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
                .then((response) => {
                  this.cc_loading = false;
                  this.handleResponse(response, true);
                })
                .catch((error) => {
                  this.cc_loading = false;
                  this.handleFaltalError(error);
                });
            }
          });
      }
    },
    rejectAsync() {
      if (this.item && this.item.id) {
        let formData = new FormData();
        formData.append("id", this.item.id);
        this.rj_loading = true;
        API.post("/Ticket/RejectAsync", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            this.rj_loading = false;
            this.handleResponse(response, true);
          })
          .catch((error) => {
            this.rj_loading = false;
            this.handleFaltalError(error);
          });
      }
    },
    closeTicketAsync() {
      if (this.item && this.item.id) {
        let formData = new FormData();
        formData.append("id", this.item.id);
        this.cta_loading = true;
        API.post("/Ticket/CloseTicketAsync", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            this.cta_loading = false;
            this.handleResponse(response, true);
          })
          .catch((error) => {
            this.cta_loading = false;
            this.handleFaltalError(error);
          });
      }
    },
    validateReject(statusId) {
      const isAdmin =
        this.user && this.user.roleName == "Administrador" ? true : false;
      const statusIsNew = this.getStatusName(statusId) == "Nuevo - Sin atender";
      return isAdmin && statusIsNew;
    },
    validateCancel(statusId) {
      const isClient =
        this.user && this.user.roleName == "Cliente" ? true : false;
      const statusIsNew = this.getStatusName(statusId) == "Nuevo - Sin atender";
      const statusIOpened = this.getStatusName(statusId) == "Abierto";
      return isClient && (statusIsNew || statusIOpened);
    },
    validateReset(statusId) {
      const isClient =
        this.user && this.user.roleName == "Cliente" ? true : false;
      const statusIsResolved = this.getStatusName(statusId) == "Resuelto";
      return isClient && statusIsResolved;
    },
    validateCloseTicket(statusId) {
      const isClient =
        this.user && this.user.roleName == "Cliente" ? true : false;
      const statusIsResolved = this.getStatusName(statusId) == "Resuelto";
      return isClient && statusIsResolved;
    },
    getUserData() {
      var userData = localStorage.getItem("user_data");
      if (userData) {
        this.user = JSON.parse(userData);
        //console.log(this.user);
      }
    },
  },
};
</script>
<style lang="scss" src="./TicketItem.scss">
</style>

