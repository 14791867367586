<template>
  <div class="row">
    <div
      class="col-md-8"
      :class="{ 'col-md-12': user && user.roleName == 'Cliente' }"
    >
      <div class="row header-data-search">
        <div class="col-12">
          <Breadcrumb
            :title="'Detalle ticket'"
            :listItem="breadcrumbListItem"
          />
        </div>
      </div>
      <ul class="list-group ticket">
        <TicketItem
          :item="item"
          :status="ticketStatus"
          :rule="rule"
          @onCompleted="onCompleted"
        />
      </ul>
      <h4 class="mt-3 mb-2">
        Todos los comentarios ({{
          item && item.comments ? item.comments.length : 0
        }})
      </h4>
      <p class="mb-0 text-right">
        <button
          class="btn btn-link d-flex align-items-center"
          @click="addTracking"
          v-if="rule && rule.canCreate"
        >
          <i class="fas fa-plus mr-2"></i>
          <span>Nuevo</span>
        </button>
      </p>
      <div>
        <ul class="list-group tracking">
          <Tracking
            v-for="(item, index) in trackingPaginador(item.comments)"
            :key="index"
            :item="item"
            :users="users"
          />
        </ul>
        <b-pagination
          v-model="trackingPage"
          :total-rows="trackingTotalRows"
          :per-page="trackingPageSize"
          class="my-0 mt-3"
          size="sm"
          align="right"
          v-if="trackingTotalRows > trackingPageSize"
        >
          <span class="text-info" slot="first-text">First</span>
          <span class="text-info" slot="prev-text">Prev</span>
          <span class="text-info" slot="next-text">Next</span>
          <span class="text-info" slot="last-text">Last</span>
          <span slot="page" slot-scope="{ page, active }">
            <b v-if="active">{{ page }}</b>
            <i v-else>{{ page }}</i>
          </span>
        </b-pagination>
      </div>
    </div>
    <div class="col-md-4" v-if="user && user.roleName != 'Cliente'">
      <div class="card">
        <div
          class="card-header d-flex justify-content-between align-items-center"
        >
          <h4 class="mb-0">Tareas</h4>
          <button
            class="btn btn-link d-flex align-items-center"
            @click="addTask"
            v-if="
              validateTicketNameStatus() &&
              rule &&
              rule.task &&
              rule.task.canCreate
            "
          >
            <i class="fas fa-plus mr-2"></i>
            <span>Nuevo</span>
          </button>
        </div>
        <div class="card-body">
          <ul class="list-group">
            <Task
              v-for="(item, index) in taskPaginador(item.tasks)"
              :key="index"
              :item="item"
              @onEditTask="editTask"
              :status="taskStatus"
            />
          </ul>
          <b-pagination
            v-model="taskPage"
            :total-rows="taskTotalRows"
            :per-page="taskPageSize"
            class="my-0 mt-3"
            size="sm"
            align="right"
            v-if="taskTotalRows > taskPageSize"
          >
            <span class="text-info" slot="first-text">First</span>
            <span class="text-info" slot="prev-text">Prev</span>
            <span class="text-info" slot="next-text">Next</span>
            <span class="text-info" slot="last-text">Last</span>
            <span slot="page" slot-scope="{ page, active }">
              <b v-if="active">{{ page }}</b>
              <i v-else>{{ page }}</i>
            </span>
          </b-pagination>
        </div>
      </div>
    </div>

    <b-modal ref="modalTask" hide-footer id="modalTask" :title="modalTitle">
      <TaskForm :item="task" @onCompleted="onTaskCompleted" />
    </b-modal>

    <b-modal
      ref="modalEditTask"
      hide-footer
      id="modalEditTask"
      :title="modalTitle"
    >
      <EditTaskForm
        :item="task"
        :status="taskStatus"
        :ticketStatus="getTicketNameStatus()"
        @onCompleted="onEditTaskCompleted"
      />
    </b-modal>

    <b-modal
      ref="modalTracking"
      hide-footer
      id="modalTracking"
      :title="modalTitle"
    >
      <TrackingForm :item="tracking" @onCompleted="onTrackingCompleted" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TicketItem from "@/pages/components/ticket/TicketItem.vue";
import Tracking from "@/pages/components/tracking/Tracking.vue";
import Breadcrumb from "@/pages/components/Breadcrumb.vue";
import Task from "@/pages/components/task/Task.vue";
import TaskForm from "@/pages/components/task/TaskForm.vue";
import EditTaskForm from "@/pages/components/task/EditTaskForm.vue";
import TrackingForm from "@/pages/components/tracking/TrackingForm.vue";
import API from "@/services/Api";
import NProgress from "nprogress";
import store from "@/store";
export default {
  components: {
    TicketItem,
    Task,
    Tracking,
    TaskForm,
    EditTaskForm,
    Breadcrumb,
    TrackingForm,
  },

  data() {
    return {
      task: {},
      tracking: {},
      modalTitle: "registro",
      ticketId: 0,
      taskStatus: [],
      ticketStatus: [],
      users: [],
      user: null,
      taskPage: 1,
      taskPageSize: 4,
      trackingPage: 1,
      trackingPageSize: 4,
      rule: null,
      breadcrumbListItem: [
        {
          title: "Mis Tickets",
          route: "mytickets",
        },
      ],
    };
  },
  created() {
    this.getUserData();
  },
  mounted() {
    this.ticketId = this.$route.params.id;
    this.getTicketStatus();
    this.getTaskStatus();
    this.getUsers();
  },
  computed: {
    ...mapState({
      item: (state) => state.ticket.ticket,
    }),
    taskTotalRows() {
      return this.item && this.item.tasks && this.item.tasks.length
        ? this.item.tasks.length
        : 0;
    },
    trackingTotalRows() {
      return this.item && this.item.comments && this.item.comments.length
        ? this.item.comments.length
        : 0;
    },
    vRule() {
      return this.rule;
    },
  },
  methods: {
    addTask() {
      this.task = {};
      this.modalTitle = "Nuevo registro";
      this.$refs["modalTask"].show();
    },
    editTask(item) {
      this.task = item;
      this.modalTitle = "Editar registro";
      this.$refs["modalEditTask"].show();
    },
    onTaskCompleted(evt) {
      if (evt) {
        this.fetchTicketById();
      }
      this.$refs["modalTask"].hide();
    },
    onEditTaskCompleted(evt) {
      if (evt) {
        this.fetchTicketById();
      }
      this.$refs["modalEditTask"].hide();
    },
    addTracking() {
      this.task = {};
      this.modalTitle = "Nuevo registro";
      this.$refs["modalTracking"].show();
    },
    onTrackingCompleted(evt) {
      this.tracking = {};
      if (evt) {
        this.fetchTicketById();
      }
      this.$refs["modalTracking"].hide();
    },
    fetchTicketById() {
      if (this.ticketId > 0) {
        NProgress.start();
        API.get("Ticket/GetFullByIdAsync?ticketId=" + this.ticketId)
          .then(function (response) {
            NProgress.done();
            store.commit("SET_TICKET", response.data.result);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getTaskStatus() {
      API.get("Status/GetByTypeAsync?statusType=3")
        .then((response) => {
          this.taskStatus = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getTicketStatus() {
      API.get("Status/GetByTypeAsync?statusType=2")
        .then((response) => {
          this.ticketStatus = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getUsers() {
      API.get("User/GetAllAsync")
        .then((response) => {
          this.users = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    onCompleted(evt) {
      if (evt) {
        this.fetchTicketById();
      }
    },
    taskPaginador(items) {
      const indiceInicio = (this.taskPage - 1) * this.taskPageSize;
      const indiceFinal =
        indiceInicio + this.taskPageSize > items.length
          ? items.length
          : indiceInicio + this.taskPageSize;
      return items.slice(indiceInicio, indiceFinal);
    },
    trackingPaginador(items) {
      const indiceInicio = (this.trackingPage - 1) * this.trackingPageSize;
      const indiceFinal =
        indiceInicio + this.trackingPageSize > items.length
          ? items.length
          : indiceInicio + this.trackingPageSize;
      return items.slice(indiceInicio, indiceFinal);
    },
    getUserData() {
      var userData = localStorage.getItem("user_data");
      if (userData) {
        this.user = JSON.parse(userData);
        //console.log(this.user);
      }
    },
    getTicketNameStatus() {
      const ticketId = this.item ? this.item.ticketStatusId : 0;
      const ticketStatus = this.ticketStatus.find((x) => x.id == ticketId);
      return ticketStatus ? ticketStatus.name : "";
    },
    validateTicketNameStatus() {
      const ticketId = this.item ? this.item.ticketStatusId : 0;
      const ticketStatusItem = this.ticketStatus.find((x) => x.id == ticketId);

      // console.log("wait ticketStatus: ", ticketStatusItem);
      return ticketStatusItem
        ? ticketStatusItem.name == "Abierto" ||
            ticketStatusItem.name == "En proceso" ||
            ticketStatusItem.name == "Nuevo - Sin atender" ||
            ticketStatusItem.name == "No resuelto"
        : false;
    },
  },

  beforeRouteEnter(to, from, next) {
    const id = to.params.id;
    if (id > 0) {
      NProgress.start();
      API.get("Ticket/GetFullByIdAsync?ticketId=" + id)
        .then(function (response) {
          NProgress.done();
          store.commit("SET_TICKET", response.data.result);
          // console.log("ICKET: ", response.data.result);
          next((vm) => {
            const menus = vm.$store.state.menus;
            vm.rule = menus.find((x) => x.pathName == to.name);
            if (vm.rule) {
              vm.rule.task = menus.find((x) => x.name == "Tareas");
              //console.log(vm.rule);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
