<template>
  <li class="list-group-item">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h4 class="mb-1">
          <span class="s-icon text-muted">
            <i class="fas fa-user-circle mr-1"></i>
            {{ getUserName() }}
          </span>
          <small class="s-icon text-muted ml-2">
            <i class="far fa-calendar mr-1"></i>
            {{ item.created | datetime }}
          </small>
        </h4>
        <p style="white-space: normal">{{ item.commentText }}</p>
        <div>
          <i class="fas fa-paperclip mr-2"></i>
          <small class="text-muted"
            >Adjuntos ({{
              item && item.attachments ? item.attachments.length : 0
            }})</small
          >
          <ul
            class="ml-3"
            v-if="item && item.attachments && item.attachments.length"
          >
            <li v-for="(attach, index) in item.attachments" :key="index">
              <a
                class="d-flex flex-row align-items-center"
                :href="getPath(attach.path)"
                download
                target="_blank"
              >
                <i class="fas fa-circle mr-2" style="font-size: 0.7rem"></i>
                {{ attach.fileName }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import Global from "@/services/Global";
export default {
  props: {
    item: {
      type: Object,
    },
    users: {
      type: Array,
    },
  },
  methods: {
    getUserName() {
      var name = "";
      if (
        this.item &&
        this.item.createdById &&
        this.users &&
        this.users.length
      ) {
        const user = this.users.find((x) => x.id == this.item.createdById);
        name = user ? `${user.firstName} ${user.lastName}` : "N/A";
      }
      return name;
    },
    getPath(path) {
      // var protocol = window.location.protocol !== "https:" ? "http:" : "https:";
      // var url = "gpassistance.tk";
      // var url = "gperti-001-site3.atempurl.com/GPAssist";
      // return `${protocol}//${baseURL}/${path}`;

      const baseURL = Global.getBaseURL();
      console.log("baseURL:path", baseURL);
      return `${baseURL}/${path}`;
    },
    getFileName(path) {
      return path;
    },
  },
};
</script>

<style lang="scss">
.tracking {
  li {
    background-color: #f7f7f7;
    .s-icon {
      font-size: 0.7rem;
    }
  }
}
</style>
