<template>
  <li class="list-group-item p-0 mb-2 pointer" @click="editTask(item)">
    <div class="px-3 pt-2">
      <h4 class="mb-0">{{item.name}}</h4>
      <p class="text-muted">{{item.description}}</p>
      <hr class="m-0" />
    </div>
    <div class="px-3 py-2">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <i class="fas fa-user-circle mr-2"></i>
          <span>{{getUserName()}}</span>
        </div>
        <div class="d-flex">
          <i class="fas fa-circle mr-2" hidden></i>
          <span class="badge badge-light">{{getStatusName()}}</span>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
// import Asignee from "@/pages/components/Asignee.vue";
import API from "@/services/Api";
export default {
  props: {
    item: {
      type: Object
    },
    status: {
      type: Array
    }
  },
  components: {},
  data() {
    return {
      assignedUsers: []
    };
  },
  mounted() {
    this.getAssignedUsers();
  },
  methods: {
    getAssignedUsers() {
      API.get("User/GetAllAsync")
        .then(response => {
          this.assignedUsers = response.data.result;
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    editTask(item) {
      this.$emit("onEditTask", item);
    },
    getStatusName() {
      var taskStatus = "";
      if (
        this.item &&
        this.item.taskStatusId &&
        this.status &&
        this.status.length
      ) {
        const status = this.status.find(x => x.id == this.item.taskStatusId);
        taskStatus = status ? status.name : "";
      }
      return taskStatus;
    },
    getUserName() {
      var name = "";
      if (
        this.item &&
        this.item.taskStatusId &&
        this.assignedUsers &&
        this.assignedUsers.length
      ) {
        const user = this.assignedUsers.find(
          x => x.id == this.item.assignedUserId
        );
        name = user ? `${user.firstName} ${user.lastName}` : "N/A";
      }
      return name;
    }
  }
};
</script>

<style lang="scss" scoped>
.asignee {
  padding: 15px;
  display: flex;
  margin: 1px;
  display: flex;
  align-items: center;
  height: 55px;
  margin-bottom: 2px;
  .form-group {
    line-height: 10px;
    select {
      min-height: 26px;
      height: 26px;
      padding: 0 10px;
      border-color: transparent;
      box-shadow: none;
    }
  }

  .x-icon {
    width: 40px;
    min-width: 40px;
    font-size: 1.35rem;
    text-align: center;
    i {
      color: #b7b7b7;
    }
  }
}
</style>
