<template>
  <div class="kreports">
    <div class="row header-data-search">
      <div class="col-12">
        <div class="filter-box">
          <div class="d-flex">
            <div class="bd-datepicker">
              <i class="far fa-calendar-alt"></i>
              <datepicker
                v-model="from"
                name="startDate"
                :disabled-dates="{ from: new Date() }"
                class="mx-1"
              ></datepicker>
            </div>
            <div class="bd-datepicker">
              <i class="far fa-calendar-alt"></i>
              <datepicker
                v-model="to"
                name="endDate"
                :disabled-dates="{ from: new Date() }"
                class="mx-1"
              ></datepicker>
            </div>
          </div>

          <select class="custom-select mx-1" v-model="companyId">
            <option selected value>-- Empresa --</option>
            <option
              v-for="(item, index) in companies"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>

          <button class="btn btn-info" @click="onSearch">
            <i class="fas fa-search"></i>
            <span class="d-none d-sm-block ml-2">Buscar</span>
          </button>
          <button class="btn btn-info" @click="onExport">
            <i class="fas fa-file-excel"></i>
            <span class="d-none d-sm-block ml-2">Export Xlsx</span>
          </button>
        </div>
      </div>
    </div>

    <PageLoading v-if="loading" />
    <div class="table-box" v-else-if="listItem && listItem.length && !loading">
      <table class="table table-bordered bg-white" style="width: auto">
        <thead>
          <tr>
            <th style="min-width: 100px" width="140px">Número de ticket</th>
            <th>Quién reporta</th>
            <th># Ticket Predecesor</th>
            <th>Asunto</th>
            <th>Descripción del ticket</th>
            <th>Categoría</th>
            <th>Prioridad</th>
            <th>Estatus</th>
            <th>Fecha identificación</th>
            <!-- <th>Comentarios</th> -->
            <th>Seguimiento</th>
            <th>Fecha compromiso</th>
            <th>Fecha cierre</th>
            <th>Tiempo de atención (horas)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listItem" :key="index">
            <td>{{ item.folio }}</td>
            <td>{{ item.createdBy }}</td>
            <td>{{ item.prevFolio }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.description }}</td>
            <td class="text-center">{{ item.category }}</td>
            <td class="text-center">{{ item.severity }}</td>
            <td class="text-center">{{ item.status }}</td>
            <td class="text-center">{{ item.created | date }}</td>
            <!-- <td>{{ item.commentText }}</td> -->
            <td>{{ item.assignedUser }}</td>
            <td>{{ item.commitmentDate | date }}</td>

            <td v-if="item.closingDate">{{ item.closingDate | date }}</td>
            <td v-else></td>

            <td>{{ item.timeBetweenChanges }}</td>
          </tr>
        </tbody>
      </table>
      <b-pagination
        v-model="page"
        :total-rows="totalRows"
        :per-page="pageSize"
        last-number
        last-text="LAST"
        class="my-0 mt-3 pagination-custom"
        size="sm"
        align="right"
        v-if="totalRows > pageSize"
        @change="handlePageChange"
      >
        <button
          slot="first-text"
          class="btn btn-link btn-sm"
          @click="fetchList(1)"
        >
          First
        </button>
        <button
          slot="prev-text"
          class="btn btn-link btn-sm"
          @click="fetchList(page - 1)"
        >
          Prev
        </button>
        <button
          slot="next-text"
          class="btn btn-link btn-sm"
          @click="fetchList(page + 1)"
        >
          Next
        </button>
        <button slot="last-text" class="btn btn-link btn-sm">Last</button>
        <button
          slot="page"
          class="btn btn-link"
          slot-scope="{ page, active }"
          @click="fetchList(page)"
        >
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </button>
      </b-pagination>
    </div>
    <div v-else type="alert" class="alert alert-warning">{{ noDataFound }}</div>
  </div>
</template>

<script>
import Breadcrumb from "@/pages/components/Breadcrumb.vue";
import API from "@/services/Api";
import Global from "@/services/Global";
import PageLoading from "@/pages/components/PageLoading.vue";
import Datepicker from "vuejs-datepicker";
import DSpinner from "@/components/DSpinner";
import FileSaver from "file-saver";
import * as dayjs from "dayjs";
export default {
  components: {
    Breadcrumb,
    PageLoading,
    Datepicker,
    DSpinner,
  },
  data() {
    return {
      projects: [],
      companies: [],
      companyId: "",
      projectId: "",
      loading: false,
      item: {},
      totalRows: 1,
      page: 1,
      pageSize: 10,
      pageOptions: [5, 10, 15],
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      modalTitle: "Registro",
      listItem: [],
      filterStatusId: "",
      totalPages: 0,
      rule: null,
      lastnumber: 0,
      from: new Date(),
      to: new Date(new Date()),
    };
  },
  mounted() {
    this.setDateFilter();
    // this.fetchList(1);
    this.getCompanies();
  },
  computed: {
    noDataFound() {
      return Global.noDataFound;
    },
  },
  methods: {
    fetchList(page) {
      this.loading = true;
      this.page = page;
      let url = `Report/GetReportsAsync?page=${page}&pageSize=${this.pageSize}`;
      if (this.from) {
        // console.log("FROM", dayjs(this.from).format("YYYY-MM-DD"));
        url = url + `&from=${dayjs(this.from).format("YYYY-MM-DD")}`;
      }
      if (this.to) {
        url = url + `&to=${dayjs(this.to).format("YYYY-MM-DD")}`;
      }
      if (this.companyId && this.companyId > 0) {
        url = url + `&companyId=${this.companyId}`;
      }
      // console.log("URL", url);
      API.get(url)
        .then((response) => {
          this.loading = false;
          // console.log("GetReportsAsync: ", response.data);
          if (response && response.data && response.data.result) {
            this.listItem = response.data.result.resultItems;
            this.totalRows = response.data.result.totalItems;
            this.totalPages = response.data.result.totalPages;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log("error", error);
        });
    },
    getCompanies() {
      API.get("Company/GetAllAsync")
        .then((response) => {
          this.companies =
            response && response.data && response.data.result
              ? response.data.result
              : [];
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getProjects() {
      API.get("Project/GetAllAsync")
        .then((response) => {
          this.projects = response.data.result || [];
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    onSearch() {
      this.item = {};
      this.fetchList(1);
    },
    onExport() {
      this.loading = true;
      const page = 1;
      const pageSize = 1000000;
      let url = `Report/DownloadReportsAsync?page=${page}&pageSize=${pageSize}`;
      if (this.from) {
        // url = url + `&from=${this.from}`;
        url = url + `&from=${dayjs(this.from).format("YYYY-MM-DD")}`;
      }
      if (this.to) {
        // url = url + `&to=${this.to}`;
        url = url + `&to=${dayjs(this.to).format("YYYY-MM-DD")}`;
      }
      if (this.companyId && this.companyId > 0) {
        url = url + `&companyId=${this.companyId}`;
      }
      API.get(url, { responseType: "blob" })
        .then((response) => {
          this.loading = false;
          const fileName = "report.xlsx";
          FileSaver.saveAs(response.data, fileName);
        })
        .catch((error) => {
          this.loading = false;
          console.log("error", error);
        });
    },
    setDateFilter() {
      this.to = dayjs(this.to).format();
      this.from = dayjs(this.from).format();
    },
    handlePageChange(value) {
      this.item = {};
      this.fetchList(value);
    },
  },
};
</script>

<style lang="scss">
.bd-datepicker {
  position: relative;
  i {
    position: absolute;
    right: 10px;
    z-index: 2;
    top: 8px;
  }
  input {
    border-radius: 4px;
    color: #222a42;
    border: 1px solid;
    border-color: rgba(29, 37, 59, 0.5);
    height: 32px;
    display: block;
    width: 100%;

    padding: 0.5rem 0.7rem;
    font-size: 0.875rem;
    line-height: 1.42857;

    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    min-width: 100px;
  }
}
.custom-select {
  min-width: 150px;
  height: 33px;
}
.table-box {
  overflow: auto;
  margin-top: 5px;
  background-color: white;
  table {
    height: auto;
    display: block;
  }
}
</style>
