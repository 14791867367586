import { render, staticRenderFns } from "./ContactForm.vue?vue&type=template&id=56e8fc6e&scoped=true"
import script from "./ContactForm.vue?vue&type=script&lang=js"
export * from "./ContactForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56e8fc6e",
  null
  
)

export default component.exports