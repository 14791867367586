<template>
  <li class="list-group-item">
    <div class="d-flex">
      <div class="avatar">{{tagName}}</div>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class>
          <h4 class="mb-0">{{fullName}}</h4>
          <p class="text-muted">{{item.email}}</p>
        </div>
        <base-dropdown tag="div" title-tag="a" class="nav-item fiut" menu-classes="dropdown-navbar">
          <a
            slot="title"
            href="javascript:void(0)"
            class="dropdown-toggle nav-link"
            data-toggle="dropdown"
            aria-expanded="true"
          >
            <div class="photo">
              <i class="fas fa-ellipsis-v text-muted"></i>
            </div>
          </a>
          <li class="nav-link">
            <button type="button" class="btn btn-link" @click="editItem(item)">
              <i class="fas fa-info-circle fa-lg mr-2"></i>
              <span>Editar</span>
            </button>
          </li>
          <li class="nav-link">
            <button type="button" class="btn btn-link text-danger" @click="deleteItem(item)">
              <i class="fas fa-info-circle fa-lg mr-2"></i>
              <span>Eliminar</span>
            </button>
          </li>
        </base-dropdown>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    fullName() {
      return `${this.item.firstName} ${this.item.lastName}`;
    },
    tagName() {
      const fn =
        this.item && this.item.firstName
          ? this.item.firstName.substr(0, 1)
          : "";
      const ln =
        this.item && this.item.lastName ? this.item.lastName.substr(0, 1) : "";
      return `${fn}${ln}`;
    }
  },
  methods: {
    editItem(item) {
      this.$emit("onEdit", item);
    },
    deleteItem(item) {
      this.$emit("onDelete", item);
    }
  }
};
</script>

<style lang="scss">
.tracking {
  li {
    background-color: #f7f7f7;
  }
}
</style>
