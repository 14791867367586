<template>
  <li class="list-group-item">
    <div class="item-wrapper">
      <div class="avatar pull-left">
        <i class="fas fa-cog fa-lg text-muted"></i>
      </div>
      <div class="inner-content">
        <div style="overflow:hidden">
          <h4 class="mb-0">{{item.subject}}</h4>
          <p class="text-muted" v-html="item.body"></p>
        </div>
        <base-dropdown tag="div" title-tag="a" class="nav-item fiut" menu-classes="dropdown-navbar">
          <a
            slot="title"
            href="javascript:void(0)"
            class="dropdown-toggle nav-link"
            data-toggle="dropdown"
            aria-expanded="true"
          >
            <div class="photo">
              <i class="fas fa-ellipsis-v text-muted"></i>
            </div>
          </a>
          <li class="nav-link">
            <button type="button" class="btn btn-link" @click="editItem(item)">
              <i class="fas fa-info-circle fa-lg mr-2"></i>
              <span>Editar</span>
            </button>
          </li>
          <li class="nav-link">
            <button type="button" class="btn btn-link text-danger" @click="deleteItem(item)">
              <i class="fas fa-info-circle fa-lg mr-2"></i>
              <span>Eliminar</span>
            </button>
          </li>
        </base-dropdown>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  components: {},
  methods: {
    editItem(item) {
      this.$emit("onEdit", item);
    },
    deleteItem(item) {
      this.$emit("onDelete", item);
    }
  }
};
</script>
