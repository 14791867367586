<template>
  <div>
    <div class="row header-data-search">
      <div class="col-12 col-md-12 pt-3">
        <Breadcrumb :title="'Tickets'" :showDashboard="rule && rule.canRead" />
      </div>
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
        <div class="filter-box">
          <span class="mr-3 text-nowrap">Filtrar por:</span>
        </div>
      </div>
      <div class="col-12 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
        <div class="filter-box">
          <div class="form-group flex-column d-flex">
            <div class="d-flex mb-17">
              <div class="bd-datepicker datepicker-container">
                <label for class="text-right small text-muted">Fecha de Inicio</label>
                <datepicker v-model="from" name="startDate" :disabled-dates="{ from: new Date() }" class="mx-1">
                </datepicker>
                <i class="far fa-calendar-alt datepicker-icon"></i>
              </div>
              <div class="bd-datepicker datepicker-container">
                <label for class="text-right small text-muted">Fecha de Fin</label>
                <datepicker v-model="to" name="endDate" :disabled-dates="{ from: new Date() }" class="mx-1">
                </datepicker>
                <i class="far fa-calendar-alt datepicker-icon"></i>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-12 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
        <div class="filter-box">
          <div class="form-group d-flex flex-column">
            <label for class="text-right small text-muted">Cliente</label>
            <select class="custom-select" v-model="filterClientUserId">
              <option selected value>-- Todos --</option>
              <option v-for="(item, index) in userClients" :key="index" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="form-group d-flex flex-column">
            <label for class="text-right small text-muted">Estatus</label>
            <select class="custom-select" v-model="filterStatusId">
              <option selected value>-- Todos --</option>
              <option v-for="(item, index) in status" :key="index" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <button class="btn btn-info mt-2 btn-center" @click="fetchList(1)">
            <i class="fas fa-search btn-icon"></i>
            <span class="btn-text">Buscar</span>
          </button>
          <button class="btn btn-info mt-2 btn-center" @click="onNew">
            <i class="fas fa-plus btn-icon"></i>
            <span class="btn-text">Nuevo Ticket</span>
          </button>
        </div>
      </div>
    </div>

    <PageLoading v-if="loading" />
    <div v-else-if="listItem && listItem.length && !loading">
      <ul class="list-group ticket">
        <!-- <TicketItem v-for="(item, index) in listItem" :key="index" :item="item" /> -->
        <TicketItem v-for="(item, index) in listItem" :key="index" :item="item" :status="status" />
      </ul>
      <div class="custom-flex-right">
        <label class="text-info" for="pageSize">Items per page:</label>
        <select @change="fetchList(1)" class="custom-select-md" v-model="pageSize" id="pageSize">
          <option>5</option>
          <option>10</option>
          <option>15</option>
          <option>20</option>
        </select>
      </div>
      <b-pagination hidden v-model="page" :total-rows="totalRows" :per-page="pageSize"
        class="my-0 mt-3 pagination-custom" size="sm" align="right" v-if="totalRows > pageSize">
        <button slot="first-text" class="btn btn-link btn-sm" @click="fetchList(1)">
          First
        </button>
        <button slot="prev-text" class="btn btn-link btn-sm" @click="fetchList(page - 1)">
          Prev
        </button>
        <button slot="next-text" class="btn btn-link btn-sm" @click="fetchList(page + 1)">
          Next
        </button>
        <button slot="last-text" class="btn btn-link btn-sm" @click="fetchList(totalPages)">
          Last
        </button>

        <button slot="page" class="btn btn-link" slot-scope="{ page, active }" @click="fetchList(page)">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </button>
      </b-pagination>
      <b-pagination v-model="page" :total-rows="totalRows" :per-page="pageSize" class="my-0 mt-3" size="sm"
        align="right" v-if="totalRows > pageSize" @change="fetchList($event)">
        <span class="text-info" slot="first-text">First</span>
        <span class="text-info" slot="prev-text">Prev</span>
        <span class="text-info" slot="next-text">Next</span>
        <span class="text-info" slot="last-text">Last</span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
    </div>

    <div v-else type="alert" class="alert alert-warning">{{ noDataFound }}</div>

    <b-modal ref="modalTicket" hide-footer id="modalTicket" no-close-on-backdrop :title="modalTitle">
      <TicketForm :item="item" @onCompleted="onCompleted" />
    </b-modal>

    <!-- <pre>{{rule}}</pre> -->
  </div>
</template>

<script>
import TicketForm from "@/pages/components/ticket/TicketForm.vue";
import TicketItem from "@/pages/components/ticket/TicketItem.vue";
import Breadcrumb from "@/pages/components/Breadcrumb.vue";
import API from "@/services/Api";
import Global from "@/services/Global";
import PageLoading from "@/pages/components/PageLoading.vue";
import Datepicker from "vuejs-datepicker";
import * as dayjs from "dayjs";
export default {
  components: {
    TicketForm,
    TicketItem,
    Breadcrumb,
    PageLoading,
    Datepicker,
  },
  data() {
    return {
      status: [],
      users: [],
      loading: false,
      item: {},
      totalRows: 1,
      page: 1,
      pageSize: 5,
      pageOptions: [5, 10, 15],
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      modalTitle: "Registro",
      listItem: [],
      filterStatusId: "",
      totalPages: 0,
      rule: null,
      currentPage: 1,
      perPage: 10,
      rows: 100,
      from: null,
      to: null,
      filterClientUserId: "",
      userClients: [],
    };
  },

  mounted() {
    this.fetchList(1);
    this.getTicketStatus();
    // this.setDateFilter();
    this.getUserClients();
  },
  computed: {
    noDataFound() {
      return Global.noDataFound;
    },
    filterListItem() {
      return this.listItem.filter((item) => {
        return (
          !this.filterText ||
          item.title.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1
        );
      });
    },
  },
  methods: {
    fetchList(page) {
      this.loading = true;
      this.page = page;
      let url = `Ticket/GetTicketsByUserIdAsync?page=${page}&pageSize=${this.pageSize}&companyId=${this.filterClientUserId}${this.from ? "&startDate=" + dayjs(this.from).format("YYYY-MM-DD") : ""}${this.to ? "&endDate=" + dayjs(this.to).format("YYYY-MM-DD") : ""}`;
      if (this.filterStatusId && this.filterStatusId > 0) {
        url = url + `&filterStatusId=${this.filterStatusId}`;
      }

      console.log(url)

      API.get(url)
        .then((response) => {
          this.loading = false;
          console.log("Tickets: ", response.data);
          if (response && response.data && response.data.result) {
            this.listItem = response.data.result.resultItems;
            this.totalRows = response.data.result.totalItems;
            this.totalPages = response.data.result.totalPages;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log("error", error);
        });
    },
    setDateFilter() {
      //this from debe iniciar con el primer dia del mes
      this.from = dayjs().startOf("month").format();
      this.to = dayjs(this.to).format();
    },
    getUserClients() {
      API.get("/Company/GetAllAsync")
        .then((response) => {
          console.log("response", response.data.result)
          this.userClients = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getTicketStatus() {
      API.get("Status/GetByTypeAsync?statusType=2")
        .then((response) => {
          this.status = response.data.result || [];
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getUsers() {
      API.get("User/GetAllAsync")
        .then((response) => {
          this.users = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    onNew() {
      this.item = {};
      this.modalTitle = "Nuevo registro";
      this.$refs["modalTicket"].show();
    },
    onEdit(item) {
      this.item = item;
      this.modalTitle = "Editar registro";
      this.$refs["modalTicket"].show();
    },
    onCompleted(evt) {
      this.item = {};
      if (evt) {
        this.fetchList(1);
      }
      this.$refs["modalTicket"].hide();
    },
    onDelete(item) {
      this.axiosDelete({
        url: "tickets",
        value: item,
        routeTo: "tickets",
      });
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     const menus = vm.$store.state.menus;
  //     vm.rule = menus.find(x => x.pathName == to.name);
  //     console.log("vm.rule", vm.rule);
  //     if (vm.rule) {
  //       vm.rule = menus.find(x => x.name == "Dashboard");
  //     }
  //   });
  // }
};
</script>
<style lang="scss">
.datepicker-container {
  position: relative;
  display: inline-block;
}

.datepicker-icon {
  position: absolute;
  right: 13px !important;
  top: 68% !important;
  transform: translateY(-50%);
  pointer-events: none;
}

.mb-17 {
  margin-bottom: -17px;
}

.btn-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.bd-datepicker {
  position: relative;

  i {
    position: absolute;
    right: 10px;
    z-index: 2;
    top: 8px;
  }

  input {
    border-radius: 4px;
    color: #222a42;
    border: 1px solid;
    border-color: rgba(29, 37, 59, 0.5);
    height: 32px;
    display: block;
    width: 100%;

    padding: 0.5rem 0.7rem;
    font-size: 0.875rem;
    line-height: 1.42857;

    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    min-width: 100px;
  }
}


@media screen and (min-width: 1700px) {
  .btn-icon {
    display: none;
  }

  .btn-text {
    display: block !important;
  }

  .filter-box {
    justify-content: end !important;
  }
}


.filter-box {
  justify-content: center !important;
}

@media screen and (max-width: 1700px) {
  .btn-icon {
    display: block;
  }

  .btn-text {
    display: none !important;
  }


}
</style>