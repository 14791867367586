<template>
  <div>
    <div class="row header-data-search">
      <div class="col-12 col-md-4">
        <Breadcrumb :title="'Roles'" :listItem="breadcrumbListItem" />
      </div>
      <div class="col-12 col-md-8">
        <div class="filter-box">
          <div class="input-search justify-content-md-end" v-if="listItem && listItem.length">
            <span class="mr-3 text-nowrap">Filtrar por:</span>
            <input
              type="text"
              class="form-control form-control-sm search"
              v-model="filterText"
              placeholder="Digite para buscar"
            />
            <span class="fas-addon fa fa-search"></span>
          </div>
          <button class="btn btn-info" @click="onNew" hidden>
            <i class="fas fa-plus"></i>
            <span class="d-none d-sm-block ml-2">Nuevo</span>
          </button>
        </div>
      </div>
    </div>

    <PageLoading v-if="loading" />
    <div v-else-if="filterListItem && filterListItem.length && !loading">
      <ul class="list-group">
        <RoleItem
          v-for="(item, index) in paginador(filterListItem)"
          :key="index"
          :item="item"
          @onEdit="onEdit($event)"
          @onDelete="onDelete($event)"
        />
      </ul>
      <b-pagination
        v-model="currentPage"
        :total-rows="filterListItem.length"
        :per-page="perPage"
        class="my-0 mt-3"
        size="sm"
        align="right"
        v-if="totalRows > perPage"
      >
        <span class="text-info" slot="first-text">First</span>
        <span class="text-info" slot="prev-text">Prev</span>
        <span class="text-info" slot="next-text">Next</span>
        <span class="text-info" slot="last-text">Last</span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
    </div>

    <div v-else type="alert" class="alert alert-warning">{{noDataFound}}</div>

    <!--
    <b-modal ref="modalRole" hide-footer id="modalRole" no-close-on-backdrop :title="modalTitle">
      <RoleForm :item="item" @onCompleted="onCompleted" />
    </b-modal>-->
  </div>
</template>

<script>
import RoleItem from "@/pages/components/role/RoleItem.vue";
import RoleForm from "@/pages/components/role/RoleForm.vue";
import Breadcrumb from "@/pages/components/Breadcrumb.vue";
import API from "@/services/Api";
import Global from "@/services/Global";
import BaseComponent from "@/pages/components/BaseComponent";
import PageLoading from "@/pages/components/PageLoading.vue";
export default {
  extends: BaseComponent,
  components: {
    RoleItem,
    RoleForm,
    PageLoading,
    Breadcrumb
  },
  data() {
    return {
      loading: false,
      listItem: [],
      item: {},
      totalRows: 0,
      currentPage: 1,
      perPage: 5,
      rule: {},
      filterText: null,
      modalTitle: "Registro",
      breadcrumbListItem: [
        {
          title: "Configuraciones",
          route: "settings"
        }
      ]
    };
  },
  mounted() {
    this.fetchList();
    this.totalRows = this.listItem.length;
  },
  computed: {
    noDataFound() {
      return Global.noDataFound;
    },
    filterListItem() {
      return this.listItem.filter(item => {
        return (
          !this.filterText ||
          item.name.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1
        );
      });
    }
  },
  methods: {
    fetchList() {
      this.loading = true;
      API.get("Role/GetAllAsync",{json: true})
        .then(response => {
          this.loading = false;
          this.listItem =
            response && response.data && response.data.result
              ? response.data.result
              : [];
          this.totalRows = this.listItem.length;
        })
        .catch(error => {
          this.loading = false;
          console.log("error", error);
        });
    },
    onNew() {
      this.item = {};
      this.modalTitle = "Nuevo registro";
      this.$refs["modalRole"].show();
    },
    onEdit(item) {
      this.item = item;
      this.modalTitle = "Editar registro";
      this.$refs["modalRole"].show();
    },
    onCompleted() {
      this.item = {};
      this.fetchList();
    },
    onDelete(item) {
      this.axiosDelete({
        url: "roles",
        value: item,
        routeTo: "roles"
      });
    },
    paginador(listItem) {
      const indiceInicio = (this.currentPage - 1) * this.perPage;
      const indiceFinal =
        indiceInicio + this.perPage > listItem.length
          ? listItem.length
          : indiceInicio + this.perPage;
      return listItem.slice(indiceInicio, indiceFinal);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const menus = vm.$store.state.menus;
      vm.rule = menus.find(x => x.pathName == to.name);
    });
  }
};
</script>
