<template>
  <li class="list-group-item">
    <div class="d-flex">
      <div class="avatar">
        <i class="fas fa-building fa-lg text-muted"></i>
      </div>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class>
          <h4 class="mb-0">{{item.name}}</h4>
          <p class="text-muted">{{item.address}}</p>
        </div>
        <base-dropdown
          v-if="isEdit &&  (roleName && roleName == 'Administrador')"
          tag="div"
          title-tag="a"
          class="nav-item fiut"
          menu-classes="dropdown-navbar"
        >
          <a
            slot="title"
            href="javascript:void(0)"
            class="dropdown-toggle nav-link"
            data-toggle="dropdown"
            aria-expanded="true"
          >
            <div class="photo">
              <i class="fas fa-ellipsis-v text-muted"></i>
            </div>
          </a>
          <li class="nav-link">
            <button type="button" class="btn btn-link" @click="editItem(item)">
              <i class="fas fa-pencil-alt fa-lg mr-2"></i>
              <span>Editar</span>
            </button>
          </li>
          <li class="nav-link" v-if="roleName && roleName == 'Administrador' && contactCount == 0">
            <button type="button" class="btn btn-link text-danger" @click="deleteItem(item)">
              <i class="fas fa-trash-alt mr-2"></i>
              <span>Eliminar</span>
            </button>
          </li>
        </base-dropdown>
        <base-dropdown
          v-if="!isEdit"
          tag="div"
          title-tag="a"
          class="nav-item fiut"
          menu-classes="dropdown-navbar"
        >
          <a
            slot="title"
            href="javascript:void(0)"
            class="dropdown-toggle nav-link"
            data-toggle="dropdown"
            aria-expanded="true"
          >
            <div class="photo">
              <i class="fas fa-ellipsis-v text-muted"></i>
            </div>
          </a>

          <li class="nav-link">
            <router-link
              class="nav-item dropdown-item"
              :to="{name:'company-detail', params: { id: item.id}}"
            >
              <i class="fas fa-info-circle fa-lg mr-2"></i>
              <span>Ver detalle</span>
            </router-link>
          </li>
          <li class="nav-link">
            <button type="button" class="btn btn-link" @click="editItem(item)">
              <i class="fas fa-pencil-alt fa-lg mr-2"></i>
              <span>Editar</span>
            </button>
          </li>
          <li class="nav-link" v-if="roleName && roleName == 'Administrador'">
            <button type="button" class="btn btn-link text-danger" @click="deleteItem(item)">
              <i class="fas fa-trash-alt mr-2"></i>
              <span>Eliminar</span>
            </button>
          </li>
        </base-dropdown>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    roleName: {
      type: String
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    contactCount: {
      type: Number
    }
  },
  components: {},
  methods: {
    editItem(item) {
      this.$emit("onEdit", item);
    },
    deleteItem(item) {
      this.$emit("onDelete", item);
    }
  }
};
</script>
