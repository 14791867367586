<template>
  <form class="mt" @submit.prevent="onSubmit">
    <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{
      errorMessage
    }}</b-alert>
    <div class="form-group" v-if="item && item.id">
      <label for="id">ID: {{ item.id }}</label>
    </div>
    <div class="row">
      <!-- <div class="col-5">
        <div class="form-group">
          <label for="folio">Folio</label>
          <input
            class="form-control"
            type="text"
            name="folio"
            id="folio"
            placeholder="Folio"
            v-validate="'required'"
            v-model="item.folio"
          />
        </div>
      </div>-->
      <div class="col-12">
        <div class="form-group">
          <label for="projectId" class="mb-0">Proyecto</label>
          <select class="custom-select" id="projectId" v-model="item.projectId">
            <option
              v-for="(item, index) in projects"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="priorityId" class="mb-0">Prioridad</label>
          <select class="custom-select" id="priorityId" v-model="item.priorityId">
            <option
              v-for="(item, index) in priority"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="title">Título</label>
      <input
        class="form-control"
        type="text"
        name="title"
        id="title"
        placeholder="Título"
        v-validate="'required'"
        v-model="item.title"
      />
    </div>

    <div class="form-group">
      <label for="description">Descripción</label>
      <textarea
        name="description"
        id="description"
        rows="5"
        class="form-control"
        placeholder="Descripción"
        v-validate="'required'"
        v-model="item.description"
      ></textarea>
    </div>
    <div class="form-group">
      <div class="custom-filed">
        <button
          type="button"
          class="trigger btn btn-link btn-sm"
          @click="$refs.filesInput.click()"
        >
          <i class="fas fa-cloud-upload-alt"></i>
          <p>
            <small>upload file</small>
          </p>
        </button>
        <input
          id="validatedCustomFile"
          style="display: none; !important"
          class="custom-file-input d-none"
          type="file"
          multiple
          ref="filesInput"
          v-on:change="handleFileUpload"
        />
      </div>
    </div>
    <ul class="list-group">
      <li
        v-for="(file, index) in files"
        :key="index"
        class="
          list-group-item
          py-0
          d-flex
          justify-content-between
          align-items-center
        "
      >
        {{ file.name }}
        <button
          type="button"
          class="btn btn-link btn-sm text-warning d-flex"
          @click="removeFile(index)"
        >
          <span class="fas fa-trash mr-2"></span>
          <span>Eliminar</span>
        </button>
      </li>
    </ul>
    <div class="clearfix">
      <div class="btn-toolbar float-right pb-4">
        <base-button
          native-type="submit"
          type="primary"
          class="mb-3"
          size="sm"
          block
          :loading="s_loading"
          >Guardar</base-button
        >
      </div>
    </div>
  </form>
</template>

<script>
import BaseComponent from "@/pages/components/BaseComponent";
import API from "@/services/Api";
export default {
  extends: BaseComponent,
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      errorMessage: "",
      mailTemplateTypes: [],
      severities: [],
      ticketStatus: [],
      projects: [],
      priority: [
        { id: 19, name: "Alta" },
        { id: 20, name: "Media" },
        { id: 21, name: "Baja" },
      ],
      files: [],
    };
  },
  mounted() {
    this.getSeverities();
    this.getTicketStatus();
    this.getProjects();
  },
  computed: {
    isFormValid() {
      return !Object.keys(this.veeFields).some(
        (key) => this.veeFields[key].invalid
      );
    },
  },
  methods: {
    handleFileUpload() {
      let _files = this.$refs.filesInput.files;
      for (var i = 0; i < _files.length; i++) {
        this.files.push(_files[i]);
      }
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
    getSeverities() {
      API.get("Severity/GetAllAsync")
        .then((response) => {
          this.severities = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getTicketStatus() {
      API.get("Status/GetAllAsync")
        .then((response) => {
          this.ticketStatus = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getProjects() {
      API.get("Project/GetAllAsync")
        .then((response) => {
          this.projects = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    onSubmit() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.s_loading = true;
          let formData = new FormData();
          for (var i = 0; i < this.files.length; i++) {
            let file = this.files[i];
            formData.append("files", file);
          }

          formData.append("title", this.item.title);
          formData.append("description", this.item.description);
          formData.append("projectId", this.item.projectId);
          formData.append("CustomerSeverityId", this.item.priorityId);

          API.post("/Ticket/AddAsync", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => this.handleResponse(response, true))
            .catch(this.handleFaltalError);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
