<template>
  <li class="list-group-item mb-2">
    <div class="d-flex">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class>
          <p class="mb-0">
            <span class="s-icon text-muted">
              <i class="fas fa-user-circle fas-2x mr-2"></i>
              {{item.name}}
            </span>
          </p>
          <p class="mb-0">
            <span class="s-icon text-muted">
              <i class="fas fa-phone fas-2x mr-2"></i>
              {{item.phone}}
            </span>
          </p>
          <p class="mb-0">
            <span class="s-icon text-muted">
              <i class="far fa-envelope fas-2x mr-2"></i>
              {{item.email}}
            </span>
          </p>
        </div>

        <base-dropdown
          v-if="roleName && roleName == 'Administrador'"
          tag="div"
          title-tag="a"
          class="nav-item fiut"
          menu-classes="dropdown-navbar"
        >
          <a
            slot="title"
            href="javascript:void(0)"
            class="dropdown-toggle nav-link"
            data-toggle="dropdown"
            aria-expanded="true"
          >
            <div class="photo">
              <i class="fas fa-ellipsis-v text-muted"></i>
            </div>
          </a>

          <li class="nav-link">
            <button type="button" class="btn btn-link" @click="editItem(item)">
              <i class="fas fa-pencil-alt fa-lg mr-2"></i>
              <span>Editar</span>
            </button>
          </li>
          <li class="nav-link">
            <button type="button" class="btn btn-link text-danger" @click="deleteItem(item)">
              <i class="fas fa-info-circle fa-lg mr-2"></i>
              <span>Eliminar</span>
            </button>
          </li>
        </base-dropdown>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    roleName: {
      type: String
    }
  },
  methods: {
    editItem(item) {
      this.$emit("onEdit", item);
    },
    deleteItem(item) {
      this.$emit("onDelete", item);
    }
  }
};
</script>

<style lang="scss">
.tracking {
  li {
    background-color: #f7f7f7;
    .s-icon {
      //font-size: 0.7rem;
    }
  }
}
</style>
