<template>
  <form class="mt" @submit.prevent="onSubmit">
    <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{errorMessage}}</b-alert>

    <div class="row">
      <div class="col-7">
        <div class="form-group">
          <label for="title">Título</label>
          <input
            class="form-control"
            type="text"
            name="title"
            id="title"
            placeholder="Título"
            v-validate="'required'"
            v-model="item.title"
          />
        </div>
      </div>
      <div class="col-5">
        <div class="form-group">
          <label for="mailTemplateTypeId">Tipo Plantilla Email</label>
          <select
            class="custom-select"
            name="mailTemplateTypeId"
            id="mailTemplateTypeId"
            v-validate="'required'"
            v-model="item.mailTemplateTypeId"
          >
            <option
              v-for="(item, index) in mailTemplateTypes"
              :key="index"
              :value="item.id"
            >{{item.name}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="subject">Asunto</label>
      <input
        class="form-control"
        type="text"
        name="subject"
        id="subject"
        placeholder="Asunto"
        v-validate="'required'"
        v-model="item.subject"
      />
    </div>

    <div class="form-group">
      <label class="mb-0" for="description">
        Cuerpo del mensaje
        <span class="i-required">*</span>
      </label>
      <ckeditor :editor="editor" v-model="item.body" :config="editorConfig"></ckeditor>
      <div>
        <small class="text-muted">Máximun caracters</small>
        <small>:</small>
        <small>{{ bodylength }}</small>
        <small>
          /
          <strong>1000</strong>
        </small>
      </div>
    </div>
    <div class="clearfix">
      <div class="btn-toolbar float-right pb-4">
        <base-button
          native-type="submit"
          type="primary"
          class="mb-3"
          size="sm"
          block
          :loading="s_loading"
        >Guardar</base-button>
      </div>
    </div>
  </form>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BaseComponent from "@/pages/components/BaseComponent";
import API from "@/services/Api";

export default {
  extends: BaseComponent,
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      errorMessage: "",
      mailTemplateTypes: [],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "insertTable",
            "undo",
            "redo"
          ]
        }
      }
    };
  },
  mounted() {
    this.getMailTemplateTypes();
  },
  computed: {
    isFormValid() {
      return !Object.keys(this.veeFields).some(
        key => this.veeFields[key].invalid
      );
    },
    bodylength() {
      return this.item && this.item.body && this.item.body.length
        ? this.item.body.length
        : 0;
    }
  },
  methods: {
    getMailTemplateTypes() {
      API.get("MailTemplateType/GetAllAsync")
        .then(response => {
          this.mailTemplateTypes = response.data.result;
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    onSubmit() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.s_loading = true;
          if (!this.item.id) {
            this.axioPost({
              url: "MailTemplate/AddAsync",
              value: this.item
            });
          } else {
            this.axioPut({
              url: "MailTemplate/UpdateAsync",
              value: this.item
            });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" >
.ck-editor__editable {
  min-height: 100px !important;
}
#modalMailTemplate___BV_modal_outer_ {
  z-index: 999 !important;
}
</style>
