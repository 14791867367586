import API from "@/services/Api";
import Global from "@/services/Global";

export default {
  data() {
    return {
      d_loading: false,
      s_loading: false,
      u_loading: false
    };
  },
  methods: {

    axioPost(obj) {
      this.s_loading = true;
      API.post(obj.url, obj.value)
        .then((response) => this.handleResponse(response, true)).catch(this.handleFaltalError);
    },

    axioPut(obj) {
      this.s_loading = true;
      API.put(obj.url, obj.value)
        .then((response) => this.handleResponse(response, true)).catch(this.handleFaltalError);
    },

    axiosDelete(obj) {
      return new Promise((resolve, reject) => {
        this.d_loading = true;
        this.$swal({
          text: Global.deleteConfirmMessage,
          buttons: ["No", "Si"],
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
            this.d_loading = false;
            API.delete(`${obj.url}?id=${obj.id}`)
              .then(response => {
                this.handleResponse(response, true);
                resolve({});
              })
              .catch(error => {
                this.handleFaltalError(error);
                reject(error.response);
              });

          } else {
            this.d_loading = false;
            resolve({});
          }
        });
      });
    },

    upload(obj) {
      return new Promise((resolve, reject) => {
        API.post("fileManager/upload/" + obj.url, obj.formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            resolve(response);
          }).catch(this.handleFaltalError);
      });
    },


    handleResponse(response, reloaded) {
      this.s_loading = false;
      this.$emit('onCompleted', reloaded)
      if (response && response.data) {
        let messageType = "success";
        const errors = response.data.resultMessages;
        if (response.data.resultCode !== 1) {
          messageType = "danger"
        }
        if (errors && errors.length > 0) {
          const message = errors.join(",<br />");
          this.handleNotify(message, messageType)
        }

      } else {
        this.handleNotify(Global.fatalErrorMessage, "warning")
      }
    },

    handleFaltalError(error) {
      this.s_loading = false;
      console.log(error)
      this.handleNotify(Global.fatalErrorMessage, "danger");
    },

    handleNotify(message, messageType) {
      this.$notify({
        type: messageType,
        message: message
      });
    },

    cleanSource(source) {
      // using native JSON functions removes reactivity
      // so we can clone an object without mutating the original source
      return JSON.parse(JSON.stringify(source));
    }

  }
};
