<template>
  <div>
    <div class="row header-data-search">
      <div class="col-12 col-md-3 pt-3">
        <Breadcrumb :title="'Tickets'" />
      </div>
      <div class="col-12 col-md-9">
        <div class="row no-gutters justify-content-end">
          <div class="col-12 col-md-auto">
            <div class="filter-box">
              <span class="mr-3 text-nowrap">Filtrar por:</span>
              <div class="form-group d-flex flex-column">
                <label for class="text-right small text-muted">Agente</label>
                <select class="custom-select" v-model="filterUserId" @change="fetchList(1)">
                  <option selected value>-- Todos --</option>
                  <option v-for="(item, index) in userAgents" :key="index" :value="item.id">
                    {{ item.firstName }}
                    {{ item.lastName }}
                  </option>
                </select>
              </div>
              <div class="form-group d-flex flex-column">
                <label for class="text-right small text-muted">Cliente</label>
                <select class="custom-select" v-model="filterClientUserId" @change="fetchList(1)">
                  <option selected value>-- Todos --</option>
                  <option v-for="(item, index) in userClients" :key="index" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-auto">
            <div class="filter-box">
              <div class="form-group d-flex flex-column">
                <label for class="text-right small text-muted">Estatus</label>
                <select class="custom-select" v-model="filterStatusId" @change="fetchList(1)">
                  <option selected value>-- Todos --</option>
                  <option v-for="(item, index) in status" :key="index" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="form-group d-flex flex-column">
                <label for="xsearch" class="text-right small text-muted">Buscar</label>
                <div class="d-flex align-items-center">
                  <input name="xsearch" id="xsearch" type="text" class="form-control" placeholder="folio"
                    style="height: 30px; width: 120px" v-model="searchFolio" />
                  <button class="btn btn-primary btn-sm" @click="onSearFolio()">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
              <button class="btn btn-info" @click="onNew" v-if="rule && rule.canCreate">
                <i class="fas fa-plus"></i>
                <span class="d-none d-sm-block ml-2">Nuevo Ticket</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PageLoading v-if="loading" />
    <div v-else-if="listItem && listItem.length && !loading">
      <ul class="list-group ticket">
        <TicketItem v-for="(item, index) in listItem" :key="index" :item="item" :status="status"
          @onCompleted="onCompleted($event)" />
      </ul>
      <div class="custom-flex-right">
        <label class="text-info" for="pageSize">Items per page:</label>
        <select @change="fetchList(1)" class="custom-select-md" v-model="pageSize" id="pageSize">
          <option>5</option>
          <option>10</option>
          <option>15</option>
          <option>20</option>
        </select>
      </div>
      <b-pagination hidden v-model="page" :total-rows="totalRows" :per-page="pageSize"
        class="my-0 mt-3 pagination-custom" size="sm" align="right" v-if="totalRows > pageSize">
        <button slot="first-text" class="btn btn-link btn-sm" @click="fetchList(1)">
          First
        </button>
        <button slot="prev-text" class="btn btn-link btn-sm" @click="fetchList(page - 1)">
          Prev
        </button>
        <button slot="next-text" class="btn btn-link btn-sm" @click="fetchList(page + 1)">
          Next
        </button>
        <button slot="last-text" class="btn btn-link btn-sm" @click="fetchList(totalPages)">
          Last
        </button>

        <button slot="page" class="btn btn-link" slot-scope="{ page, active }" @click="fetchList(page)">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </button>
      </b-pagination>
      <b-pagination v-model="page" :total-rows="totalRows" :per-page="pageSize" class="my-0 mt-3" size="sm"
        align="right" v-if="totalRows > pageSize" @change="fetchList($event)">
        <span class="text-info" slot="first-text">First</span>
        <span class="text-info" slot="prev-text">Prev</span>
        <span class="text-info" slot="next-text">Next</span>
        <span class="text-info" slot="last-text">Last</span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
    </div>

    <div v-else type="alert" class="alert alert-warning">{{ noDataFound }}</div>

    <b-modal ref="modalTicket" hide-footer id="modalTicket" no-close-on-backdrop :title="modalTitle">
      <TicketForm :item="item" @onCompleted="onCompleted" />
    </b-modal>
  </div>
</template>

<script>
import TicketForm from "@/pages/components/ticket/TicketForm.vue";
import TicketItem from "@/pages/components/ticket/TicketItem.vue";
import Breadcrumb from "@/pages/components/Breadcrumb.vue";
import API from "@/services/Api";
import Global from "@/services/Global";
import PageLoading from "@/pages/components/PageLoading.vue";
export default {
  components: {
    TicketForm,
    TicketItem,
    Breadcrumb,
    PageLoading,
  },
  data() {
    return {
      status: [],
      userAgents: [],
      userClients: [],
      loading: false,
      item: {},
      totalRows: 1,
      page: 1,
      pageSize: 5,
      pageOptions: [5, 10, 15],
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      modalTitle: "Registro",
      rule: null,
      listItem: [],
      filterStatusId: "",
      filterUserId: "",
      filterClientUserId: "",
      searchFolio: "",
      currentPage: 1,
    };
  },
  mounted() {
    this.fetchList(1);
    this.getTicketStatus();
    this.getUserAgents();
    this.getUserClients();
    //this.totalRows = this.listItem.length;
  },
  computed: {
    noDataFound() {
      return Global.noDataFound;
    },
    filterListItem() {
      return this.listItem.filter((item) => {
        return (
          !this.filterText ||
          item.title.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1
        );
      });
    },
  },
  methods: {
    onSearFolio() {
      this.fetchList(1);
    },
    fetchList(page) {
      this.loading = true;
      this.page = page;

      let url = `Ticket/GetAllAsync?page=${page}&pageSize=${this.pageSize}`;
      if (this.searchFolio) {
        url = url + `&searchFolio=${this.searchFolio}`;
      }
      if (this.filterUserId && this.filterUserId > 0) {
        url = url + `&filterUserId=${this.filterUserId}`;
      }
      if (this.filterClientUserId && this.filterClientUserId > 0) {
        url = url + `&companyId=${this.filterClientUserId}`;
      }
      if (this.filterStatusId && this.filterStatusId > 0) {
        url = url + `&filterStatusId=${this.filterStatusId}`;
      }

      API.get(url)
        .then((response) => {
          this.loading = false;
          if (response && response.data && response.data.result) {
            this.listItem = response.data.result.resultItems;
            this.totalRows = response.data.result.totalItems;
            this.totalPages = response.data.result.totalPages;
            // console.log("Ticket: ", response.data.result);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log("error", error);
        });
    },
    getTicketStatus() {
      API.get("Status/GetByTypeAsync?statusType=2")
        .then((response) => {
          this.status = response.data.result || [];
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getUserAgents() {
      API.get("User/GetAgentsAsync")
        .then((response) => {
          this.userAgents = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getUserClients() {
      API.get("/Company/GetAllAsync")
        .then((response) => {
          console.log("response", response.data.result)
          this.userClients = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    onNew() {
      this.item = {};
      this.modalTitle = "Nuevo registro";
      this.$refs["modalTicket"].show();
    },
    onEdit(item) {
      this.item = item;
      this.modalTitle = "Editar registro";
      this.$refs["modalTicket"].show();
    },
    onCompleted(evt) {
      this.item = {};
      if (evt) {
        this.fetchList(1);
      }
      this.$refs["modalTicket"].hide();
    },
    onDelete(item) {
      this.axiosDelete({
        url: "tickets",
        value: item,
        routeTo: "tickets",
      });
    },
    onSearch() { },
  },
};
</script>
