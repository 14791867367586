<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row header-data-search">
        <div class="col-12">
          <Breadcrumb :title="'Empresa'" :listItem="breadcrumbListItem" />
        </div>
      </div>
      <ul class="list-group ticket">
        <CompanyItem
          :isEdit="isCompantEdit"
          :item="item"
          :roleName="roleName"
          :contactCount="contactCount"
          @onEdit="onEdit(item)"
          @onDelete="onDelete($event)"
        />
      </ul>

      <h4 class="mt-3 mb-2">Todos los contactos ({{contactCount}})</h4>
      <p class="mb-0 text-right" v-if="roleName && roleName == 'Administrador'">
        <button class="btn btn-link d-flex align-items-center" @click="addContact">
          <i class="fas fa-plus mr-2"></i>
          <span>Nuevo</span>
        </button>
      </p>
      <ul class="list-group tracking">
        <Contact
          v-for="(item, index) in item.contacts"
          :key="index"
          :item="item"
          :roleName="roleName"
          @onEdit="onContactEdit(item)"
          @onDelete="onContactDelete(item)"
        />
      </ul>
    </div>

    <!-- <pre>{{item}}</pre> -->

    <b-modal ref="modalContact" hide-footer id="modalContact" :title="modalTitle">
      <ContactForm :item="contact" @onCompleted="onContactCompleted" />
    </b-modal>

    <b-modal
      ref="modalCompany"
      hide-footer
      id="modalCompany"
      no-close-on-backdrop
      :title="modalTitle"
    >
      <CompanyForm :item="Object.assign({}, item)" @onCompleted="onCompleted" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CompanyItem from "@/pages/components/company/CompanyItem.vue";
import CompanyForm from "@/pages/components/company/CompanyForm.vue";
import Contact from "@/pages/components/contact/Contact.vue";
import ContactForm from "@/pages/components/contact/ContactForm.vue";
import Breadcrumb from "@/pages/components/Breadcrumb.vue";
import API from "@/services/Api";
import NProgress from "nprogress";
import store from "@/store";
import BaseComponent from "@/pages/components/BaseComponent";
export default {
  extends: BaseComponent,
  components: {
    CompanyItem,
    CompanyForm,
    Contact,
    ContactForm,
    Breadcrumb
  },
  data() {
    return {
      modalTitle: "registro",
      contact: {},
      isCompantEdit: true,
      companyId: 0,
      breadcrumbListItem: [
        {
          title: "Empresas",
          route: "companies"
        }
      ]
    };
  },
  created() {
    this.companyId = 0;
    this.getUserData();
  },
  computed: {
    ...mapState({
      item: state => state.ticket.ticket
    }),
    roleName() {
      const roleName = this.$store.state.roleName;
      return this.user ? this.user.roleName : roleName;
    },
    contactCount() {
      return this.item && this.item.contacts ? this.item.contacts.length : 0;
    }
  },
  methods: {
    //company
    onEdit(item) {
      //this.item = item;
      this.modalTitle = "Editar registro";
      this.$refs["modalCompany"].show();
    },
    onDelete(item) {
      this.axiosDelete({
        url: "Company/DeleteAsync",
        id: item.id
      }).then(() => {
        //this.onCompleted(false);
        this.$router.push({ name: "companies" });
      });
    },
    onCompleted(evt) {
      if (evt) {
        this.getCompanyWithContactsAsync(this.item.id);
      }
      this.$refs["modalCompany"].hide();
    },
    //contact
    addContact() {
      this.contact = {
        companyId: this.item.id
      };
      this.modalTitle = "Nuevo registro";
      this.$refs["modalContact"].show();
    },
    onContactEdit(item) {
      this.contact = Object.assign({}, item);
      this.modalTitle = "Editar registro";
      this.$refs["modalContact"].show();
    },
    onContactCompleted(evt) {
      if (evt) {
        this.getCompanyWithContactsAsync(this.item.id);
      }
      this.$refs["modalContact"].hide();
    },
    onContactDelete(item) {
      this.axiosDelete({
        url: "Contact/DeleteAsync",
        id: item.id
      }).then(() => {
        this.onCompleted(true);
      });
    },
    getUserData() {
      var userData = localStorage.getItem("user_data");
      if (userData) {
        this.user = JSON.parse(userData);
        //console.log(this.user);
      }
    },
    getCompanyWithContactsAsync(id) {
      if (id > 0) {
        NProgress.start();
        API.get(`Company/GetCompanyWithContactsAsync?companyId=${id}`)
          .then(function(response) {
            NProgress.done();
            store.commit("SET_TICKET", response.data.result);
            // next();
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    const id = to.params.id;
    if (id > 0) {
      NProgress.start();
      API.get(`Company/GetCompanyWithContactsAsync?companyId=${id}`)
        .then(function(response) {
          NProgress.done();
          store.commit("SET_TICKET", response.data.result);
          next();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.list-group-item {
  padding: 1rem 1rem;
  margin-bottom: -0.0625rem;
}
</style>
