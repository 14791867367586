<template>
  <li class="list-group-item">
    <div class="d-flex">
      <div class="avatar">
        <i class="fas fa-cog fa-lg text-muted"></i>
      </div>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class>
          <h4 class="mb-0">{{item.name}}</h4>
          <p class="text-muted">{{item.description}}</p>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  components: {},
  methods: {
    editItem(item) {
      this.$emit("onEdit", item);
    },
    deleteItem(item) {
      this.$emit("onDelete", item);
    }
  }
};
</script>
