<template>
  <div>
    <div class="row header-data-search">
      <div class="col-12 col-md-4">
        <Breadcrumb :title="'Usuarios'" :listItem="breadcrumbListItem" />
      </div>
      <div class="col-12 col-md-8">
        <div class="filter-box">
          <div class="input-search justify-content-md-end">
            <span class="mr-3 text-nowrap">Filtrar por:</span>
            <input
              type="text"
              class="form-control form-control-sm search"
              placeholder="Digite para buscar"
              v-model="filterText"
            />
            <span class="fas-addon fa fa-search"></span>
          </div>

          <button class="btn btn-info" @click="onNew">
            <i class="fas fa-plus"></i>
            <span class="d-none d-sm-block ml-2">Nuevo</span>
          </button>
        </div>
      </div>
    </div>

    <PageLoading v-if="loading" />
    <div v-else-if="filterListItem && filterListItem.length && !loading">
      <ul class="list-group">
        <UserItem
          v-for="(item, index) in paginador(filterListItem)"
          :key="index"
          :item="item"
          @onEdit="onEdit($event)"
          @onDelete="onDelete($event)"
        />
      </ul>

      <b-pagination
        v-model="currentPage"
        :total-rows="filterListItem.length"
        :per-page="perPage"
        class="my-0 mt-3"
        size="sm"
        align="right"
        v-if="totalRows > perPage"
      >
        <span class="text-info" slot="first-text">First</span>
        <span class="text-info" slot="prev-text">Prev</span>
        <span class="text-info" slot="next-text">Next</span>
        <span class="text-info" slot="last-text">Last</span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
    </div>

    <div v-else type="alert" class="alert alert-warning">{{ noDataFound }}</div>

    <b-modal
      ref="modalUser"
      hide-footer
      no-close-on-backdrop
      id="modalUser"
      :title="modalTitle"
    >
      <UserForm :item="item" :isEdit="isEdit" @onCompleted="onCompleted" />
    </b-modal>
  </div>
</template>

<script>
import UserItem from "@/pages/components/user/UserItem.vue";
import UserForm from "@/pages/components/user/UserForm.vue";
import { mapState, mapActions } from "vuex";
import Breadcrumb from "@/pages/components/Breadcrumb.vue";
import API from "@/services/Api";
import Global from "@/services/Global";
import PageLoading from "@/pages/components/PageLoading.vue";
import BaseComponent from "@/pages/components/BaseComponent";
export default {
  extends: BaseComponent,
  components: {
    UserItem,
    UserForm,
    PageLoading,
    Breadcrumb,
  },
  data() {
    return {
      status: [],
      loading: false,
      listItem: [],
      item: {},
      totalRows: 0,
      currentPage: 1,
      perPage: 5,
      filterText: "",
      modalTitle: "Registro",
      isEdit: false,
      breadcrumbListItem: [
        {
          title: "Configuraciones",
          route: "settings",
        },
      ],
    };
  },

  mounted() {
    this.fetchList();
  },
  computed: {
    noDataFound() {
      return Global.noDataFound;
    },
    filterListItem() {
      return this.listItem.filter((item) => {
        item.searchscope = `${item.firstName} ${item.lastName} ${item.email}`;
        return (
          !this.filterText ||
          item.searchscope
            .toLowerCase()
            .indexOf(this.filterText.toLowerCase()) > -1
        );
      });
    },
  },

  methods: {
    fetchList() {
      this.loading = true;
      API.get("User/GetAllAsync", { json: true })
        .then((response) => {
          this.loading = false;
          // console.log("Users", response.data.result);
          this.listItem =
            response && response.data && response.data.result
              ? response.data.result
              : [];
          this.totalRows = this.listItem.length;
        })
        .catch((error) => {
          this.loading = false;
          console.log("error", error);
        });
    },
    onNew() {
      this.item = {
        companyId: 0,
        roleId: 0,
        departmentId: 0,
      };
      // console.log("onNew");
      this.modalTitle = "Nuevo registro";
      this.isEdit = false;
      this.$refs["modalUser"].show();
    },
    onEdit(item) {
      //JSON.parse(JSON.stringify(source))
      this.item = Object.assign({}, item);
      if (!(item && item.companyId)) {
        this.item.companyId = 0;
      }

      if (this.item && this.item.roles && this.item.roles.length > 0) {
        this.item.roleId = this.item.roles[0];
      } else {
        this.item.roleId = 0;
      }

      if (
        this.item &&
        this.item.userDepartments &&
        this.item.userDepartments.length > 0
      ) {
        this.item.departmentId = this.item.userDepartments[0];
      } else {
        this.item.departmentId = 0;
      }

      // console.log("onEdit", item);
      this.modalTitle = "Editar registro";
      this.isEdit = true;
      this.$refs["modalUser"].show();
    },
    onCompleted(evt) {
      this.item = {};
      if (evt) {
        this.fetchList();
      }
      this.$refs["modalUser"].hide();
    },
    onDelete(item) {
      this.axiosDelete({
        url: "User/DeleteAsync",
        id: item.id,
      }).then(() => {
        this.currentPage = 1;
        this.onCompleted(true);
      });
    },

    paginador(listItem) {
      const indiceInicio = (this.currentPage - 1) * this.perPage;
      const indiceFinal =
        indiceInicio + this.perPage > listItem.length
          ? listItem.length
          : indiceInicio + this.perPage;
      return listItem.slice(indiceInicio, indiceFinal);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
