<template>
  <form class="mt" @submit.prevent="onSubmit">
    <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{errorMessage}}</b-alert>
    <div class="form-group" v-if="item && item.id">
      <label for="id">ID: {{item.id}}</label>
    </div>
    <div class="form-group">
      <label for="uniqueIdentifier">Identificador único</label>
      <input
        class="form-control"
        type="text"
        name="uniqueIdentifier"
        id="uniqueIdentifier"
        placeholder="Identificador único"
        v-validate="'required'"
        maxlength="3"
        v-model="item.uniqueIdentifier"
        autocomplete="off"
        :readonly="isGp()"
      />
    </div>
    <div class="form-group">
      <label for="name">Empresa</label>
      <input
        class="form-control"
        type="text"
        name="name"
        id="name"
        placeholder="Empresa"
        v-validate="'required'"
        v-model="item.name"
        autocomplete="off"
        :readonly="isGp()"
      />
    </div>
    <div class="form-group">
      <label for="address">Dirección</label>
      <textarea
        name="address"
        id="address"
        rows="5"
        class="form-control"
        placeholder="Dirección"
        v-validate="'required'"
        v-model="item.address"
      ></textarea>
    </div>
    <div class="clearfix">
      <div class="btn-toolbar float-right pb-4">
        <base-button
          native-type="submit"
          type="primary"
          class="mb-3"
          size="sm"
          block
          :loading="s_loading"
          :disabled="isGp()"
        >Guardar</base-button>
      </div>
    </div>
  </form>
</template>

<script>
import BaseComponent from "@/pages/components/BaseComponent";
export default {
  extends: BaseComponent,
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      errorMessage: ""
    };
  },
  computed: {
    isFormValid() {
      return !Object.keys(this.veeFields).some(
        key => this.veeFields[key].invalid
      );
    }
  },
  methods: {
    onSubmit() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.s_loading = true;
          if (!this.item.id) {
            this.axioPost({
              url: "Company/AddAsync",
              value: this.item
            });
          } else {
            this.axioPut({
              url: "Company/UpdateAsync",
              value: this.item
            });
          }
        }
      });
    },
    isGp() {
      //desabilitar cuando es grupo Perti
      return this.item && this.item.id == 1 ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
