<template>
  <div class="spinner-box">
    <img src="/gpassist/img/loader.gif" alt="loader" :width="width" />
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 15
    }
  }
};
</script>

<style lang="scss" scoped>
.spinner-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;
  justify-content: center;
  align-content: center;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
