<template>
  <form class="mt" @submit.prevent="onSubmit">
    <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{errorMessage}}</b-alert>
    <div class="form-group" v-if="item && item.id">
      <label for="id">ID: {{item.id}}</label>
    </div>
    <div class="form-group">
      <label for="name">Título</label>
      <input
        class="form-control"
        type="text"
        name="name"
        id="name"
        placeholder="Título"
        v-validate="'required'"
        v-model="item.name"
        autocomplete="off"
      />
    </div>

    <div class="form-group">
      <!-- <label for="priorityId" class="mb-0 mt-1">
        <small class="text-muted">Estatus</small>
      </label>
      <select class="custom-select" id="assignedUserId" v-model="item.assignedUserId">
        <option v-for="(item, index) in taskStatus" :key="index" :value="item.id">{{item.name}}</option>
      </select>-->
      <label for="priorityId" class="mb-0 mt-1">
        <small class="text-muted">Asignado a</small>
      </label>
      <select class="custom-select" id="assignedUserId" v-model="item.assignedUserId">
        <option
          v-for="(item, index) in assignedUsers"
          :key="index"
          :value="item.id"
        >{{item.firstName}} {{item.lastName}}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="description">Descripción</label>
      <textarea
        name="description"
        id="description"
        rows="5"
        class="form-control"
        placeholder="Descripción"
        v-validate="'required'"
        v-model="item.description"
      ></textarea>
    </div>
    <div class="clearfix">
      <div class="btn-toolbar float-right pb-4">
        <base-button
          native-type="submit"
          type="primary"
          class="mb-3"
          size="sm"
          block
          :loading="s_loading"
        >Guardar</base-button>
      </div>
    </div>
  </form>
</template>

<script>
import BaseComponent from "@/pages/components/BaseComponent";
import Datepicker from "vuejs-datepicker";
import { es } from "vuejs-datepicker/dist/locale";
import API from "@/services/Api";
export default {
  extends: BaseComponent,
  components: {
    Datepicker
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      es: es,
      errorMessage: "",
      taskStatus: [],
      assignedUsers: [],
      ticketId: 0
    };
  },
  mounted() {
    this.ticketId = this.$route.params.id;
    this.getTaskStatus();
    this.getAssignedUsers();
  },
  computed: {
    isFormValid() {
      return !Object.keys(this.veeFields).some(
        key => this.veeFields[key].invalid
      );
    }
  },
  methods: {
    getTaskStatus() {
      API.get("Status/GetAllAsync")
        .then(response => {
          this.taskStatus = response.data.result;
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getAssignedUsers() {
      API.get("User/GetAgentsAsync")
        .then(response => {
          this.assignedUsers = response.data.result;
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    onSubmit() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.s_loading = true;
          var task = {
            ...this.item,
            ticketId: this.ticketId,
            taskStatusId: 12
          };
          if (!task.id) {
            this.axioPost({
              url: "Task/AddAsync",
              value: task
            });
          } else {
            this.axioPut({
              url: "Task/UpdateAsync",
              value: task
            });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
