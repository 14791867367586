<template>
  <div class="auth-inner-page">
    <div class="container">
      <div class="col-lg-4 col-md-6 ml-auto mr-auto">
        <div class="text-center">
          <img class="logo" :src="'/gpassist/img/logo-perti.png'" alt />
        </div>
        <form @submit.prevent="ResetPasswordConfirm">
          <card class="card-white">
            <template slot="header">
              <h3 class="card-title">Recupera contraseña</h3>
            </template>
            <div>
              <base-input
                v-validate="'required|email'"
                name="email"
                :class="{'is-valid': getError('email')}"
                v-model="email"
                placeholder="Email"
                addon-left-icon="tim-icons icon-email-85"
              ></base-input>
              <base-input
                v-validate="'required|min:5'"
                name="password"
                :class="{'is-valid': getError('password')}"
                v-model="password"
                type="password"
                placeholder="Contraseña"
                addon-left-icon="tim-icons icon-lock-circle"
              ></base-input>
              <base-input
                v-validate="'required|min:5'"
                name="confirmPassword"
                :class="{'is-valid': getError('confirmPassword')}"
                v-model="confirmPassword"
                type="password"
                placeholder="Confirma contraseña"
                addon-left-icon="tim-icons icon-lock-circle"
              ></base-input>
            </div>

            <div slot="footer">
              <base-button
                native-type="submit"
                type="primary"
                class="mb-3"
                block
                :loading="s_loading"
              >Recuperar contraseña</base-button>
              <p class="text-center">
                <small>
                  Ya cuentas con usuario y contraseña?
                  <router-link class="link footer-link" :to="{name: 'login'}">Login</router-link>
                </small>
              </p>

              <div class="text-center" hidden>
                <a href="javascript:void(0)" class="link footer-link">¿Necesitas Ayuda?</a>
              </div>
            </div>
          </card>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import BaseComponent from "@/pages/components/BaseComponent";
export default {
  extends: BaseComponent,
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      token: ""
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    // console.log(this.$router.currentRoute.params);
  },
  methods: {
    getError(fieldName) {
      return !this.errors.first(fieldName) == "";
    },
    ResetPasswordConfirm() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.s_loading = true;
          this.$store
            .dispatch("resetPasswordConfirm", {
              email: this.email,
              password: this.password,
              resetPasswordToken: this.token
            })
            .then(response => {
              this.s_loading = false;
              // console.log("response: ", response);
              if (response && response.data) {
                let messageType = "success";
                const errors = response.data.resultMessages;
                if (response.data.resultCode !== 1) {
                  messageType = "danger";
                }
                if (errors && errors.length > 0) {
                  const message = errors.join(",<br />");
                  this.handleNotify(message, messageType);
                  this.$router.push({ name: "login" });
                }
              } else {
                this.handleNotify(Global.fatalErrorMessage, "warning");
              }
            })
            .catch(() => {
              this.s_loading = false;
            });
        }
      });
    }
  }
};
</script>
 <style lang="scss" src="./auth.scss"></style>
