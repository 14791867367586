<template>
  <form class="mt">
    <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{errorMessage}}</b-alert>
    <div class="form-group" v-if="item && item.id">
      <label for="id">ID: {{item.id}}</label>
    </div>

    <div class="form-group">
      <label for="description">Comentario</label>
      <textarea
        name="commentText"
        id="commentText"
        rows="5"
        class="form-control"
        placeholder="Comentario"
        v-validate="'required'"
        v-model="item.commentText"
      ></textarea>
    </div>
    <div class="form-group">
      <div class="custom-filed">
        <button type="button" class="trigger btn btn-link btn-sm" @click="$refs.filesInput.click()">
          <i class="fas fa-cloud-upload-alt"></i>
          <p>
            <small>upload file</small>
          </p>
        </button>
        <input
          id="validatedCustomFile"
          style="display: none; !important"
          class="custom-file-input d-none"
          type="file"
          multiple
          ref="filesInput"
          v-on:change="handleFileUpload"
        />
      </div>
    </div>
    <ul class="list-group">
      <li
        v-for="(file, index) in files"
        :key="index"
        class="list-group-item py-0 d-flex justify-content-between align-items-center"
      >
        {{ file.name }}
        <button
          type="button"
          class="btn btn-link btn-sm text-warning d-flex"
          @click="removeFile(index)"
        >
          <span class="fas fa-trash mr-2"></span>
          <span>Eliminar</span>
        </button>
      </li>
    </ul>
    <div class="clearfix">
      <div class="btn-toolbar float-right pb-4">
        <base-button
          native-type="button"
          type="primary"
          class="mb-3"
          size="sm"
          block
          :loading="s_loading"
          @click="onSubmit"
        >Guardar</base-button>

        <!-- <button
          type="button"
          class="btn btn-primary btn-sm"
          @click="onSubmit"
          :disabled="!isFormValid || s_loading"
        >Guardar</button>-->
      </div>
    </div>
  </form>
</template>

<script>
import BaseComponent from "@/pages/components/BaseComponent";
import API from "@/services/Api";
export default {
  extends: BaseComponent,
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      errorMessage: "",
      files: [],
      ticketId: 0
    };
  },
  mounted() {
    // console.log(this.$route.params);
    this.ticketId = this.$route.params.id;
  },
  computed: {
    isFormValid() {
      return !Object.keys(this.veeFields).some(
        key => this.veeFields[key].invalid
      );
    }
  },
  methods: {
    handleFileUpload() {
      let _files = this.$refs.filesInput.files;
      for (var i = 0; i < _files.length; i++) {
        this.files.push(_files[i]);
      }
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
    onSubmit() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.s_loading = true;
          let formData = new FormData();
          for (var i = 0; i < this.files.length; i++) {
            let file = this.files[i];
            formData.append("files", file);
          }

          formData.append("commentText", this.item.commentText);
          formData.append("ticketId", this.ticketId);

          API.post("/Comment/AddAsync", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
            .then(response => this.handleResponse(response, true))
            .catch(this.handleFaltalError);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
