<template>
  <div class="auth-inner-page">
    <div class="container">
      <div class="col-lg-4 col-md-6 ml-auto mr-auto">
        <div class="text-center">
          <img class="logo" :src="'/gpassist/img/logo-perti.png'" alt />
        </div>
        <form @submit.prevent="resetPassword">
          <card class="card-white">
            <template slot="header">
              <h3 class="card-title">Recupera contraseña</h3>
            </template>

            <div>
              <base-input
                v-validate="'required|email'"
                name="email"
                :class="{'is-valid': getError('email')}"
                v-model="email"
                placeholder="Email"
                addon-left-icon="tim-icons icon-email-85"
              ></base-input>
            </div>

            <div slot="footer">
              <base-button
                native-type="submit"
                type="primary"
                class="mb-3"
                block
                :loading="loading"
              >Recuperar contraseña</base-button>
              <p class="text-center">
                <small>
                  ¿Ya cuentas con usuario y contraseña?
                  <router-link class="link footer-link" :to="{name: 'login'}">Login</router-link>
                </small>
              </p>

              <div class="text-center" hidden>
                <a href="javascript:void(0)" class="link footer-link">¿Necesitas Ayuda?</a>
              </div>
            </div>
          </card>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: "",
      loading: false
    };
  },
  methods: {
    getError(fieldName) {
      return !this.errors.first(fieldName) == "";
    },
    resetPassword() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("resetPassword", this.email)
            .then(response => {
              this.loading = false;
              if (response && response.data) {
                // console.log(response.data);
                const errors = response.data.resultMessages;
                if (errors && errors.length > 0) {
                  const message = errors.join(",<br />");
                  this.$notify({
                    type: "danger",
                    message: message
                  });
                }
              } else {
                this.$notify({
                  type: "danger",
                  message: "Registro inválido"
                });
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
 <style lang="scss" src="./auth.scss"></style>
