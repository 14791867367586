export const basicOptions = {
  maintainAspectRatio: false,
  legend: {
    display: true
  },
  responsive: true,
};

export let blueChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 60,
        suggestedMax: 125,
        padding: 20,
        fontColor: "#2380f7"
      }
    }],

    xAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#2380f7"
      }
    }]
  }
}
export let barChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
    callbacks: {
      label: function (tooltipItem, data) {
        var label = data.datasets[tooltipItem.datasetIndex].label || '';
        var xtimeStr = data.datasets[tooltipItem.datasetIndex].xdate[tooltipItem.index] || '';
        // const xtimeInt = parseFloat(tooltipItem.value).toFixed(2)
        return `${label}: ${xtimeStr}`
      }
    }
  },

  scales: {
    yAxes: [{

      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 0,
        suggestedMax: 8,
        padding: 20,
        fontColor: "#9e9e9e"
      }
    }],
    xAxes: [{

      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#9e9e9e"
      }
    }]
  }

}
export let tRticketsOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
    callbacks: {
      label: function (tooltipItem, data) {
        var label = data.datasets[tooltipItem.datasetIndex].label || '';
        var xtimeStr = data.datasets[tooltipItem.datasetIndex].xdate[tooltipItem.index] || '';
        // const xtimeInt = parseFloat(tooltipItem.value).toFixed(2)
        return `${label}: ${xtimeStr}`
      }
    }
  },

  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Tiempo en Horas'
      },
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 0,
        suggestedMax: 8,
        padding: 20,
        fontColor: "#9e9e9e"
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Folio de Ticket'
      },
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#9e9e9e"
      }
    }]
  }

}

export let tRtasksOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
    callbacks: {
      label: function (tooltipItem, data) {
        var label = data.datasets[tooltipItem.datasetIndex].label || '';
        var xtimeStr = data.datasets[tooltipItem.datasetIndex].xdate[tooltipItem.index] || '';
        // const xtimeInt = parseFloat(tooltipItem.value).toFixed(2)
        return `${label}: ${xtimeStr}`
      }
    }
  },

  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Tiempo en Horas'
      },
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 0,
        suggestedMax: 8,
        padding: 20,
        fontColor: "#9e9e9e"
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Tareas'
      },
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#9e9e9e"
      }
    }]
  }

}



export let barChartOptions1 = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },

  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Tareas X Ticket'
      },
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 0,
        suggestedMax: 24,
        padding: 20,
        fontColor: "#9e9e9e",
        callback: function (value, index, values) {
          // console.log(value, index, values)
          return '' + value;
        }
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Folio de Ticket'
      },
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#9e9e9e"
      }
    }]
  }

}

export const ticketRTcharData = [{
    folio: "0FOL-4534",
    systemTime: 44,
    agentTime: 65,
    name: "task 1",
    taskCount: 8
  },
  {
    folio: "FOL-4535",
    systemTime: 46,
    agentTime: 69,
    name: "task 2",
    taskCount: 7
  },
  {
    folio: "xOL-4536",
    systemTime: 170,
    agentTime: 169,
    name: "task 3",
    taskCount: 12
  },
  {
    folio: "xOL-4537",
    systemTime: 130,
    agentTime: 139,
    name: "task 4",
    taskCount: 4
  },
  {
    folio: "xOL-4538",
    systemTime: 100,
    agentTime: 169,
    name: "task 5",
    taskCount: 13
  },
  {
    folio: "xOL-4536",
    systemTime: 160,
    agentTime: 69,
    name: "task 6",
    taskCount: 9
  }
]
