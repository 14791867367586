<template>
  <div>
    <div class="row header-data-search">
      <div class="col-12 col-md-4">
        <Breadcrumb :title="'Plantilla email'" :listItem="breadcrumbListItem" />
      </div>
      <div class="col-12 col-md-8">
        <div class="filter-box">
          <div class="input-search justify-content-md-end" v-if="listItem && listItem.length">
            <span class="mr-3 text-nowrap">Filtrar por:</span>
            <input
              type="text"
              class="form-control form-control-sm search"
              v-model="filterText"
              placeholder="Digite para buscar"
            />
            <span class="fas-addon fa fa-search"></span>
          </div>
          <!-- <button class="btn btn-info" @click="onNew">
            <i class="fas fa-plus"></i>
            <span class="d-none d-sm-block ml-2">Nuevo</span>
          </button>-->
        </div>
      </div>
    </div>

    <PageLoading v-if="loading" />
    <div v-else-if="filterListItem && filterListItem.length && !loading">
      <ul class="list-group">
        <MailTemplateItem
          v-for="(item, index) in paginador(filterListItem)"
          :key="index"
          :item="item"
          @onEdit="onEdit($event)"
          @onDelete="onDelete($event)"
        />
      </ul>

      <b-pagination
        v-model="currentPage"
        :total-rows="filterListItem.length"
        :per-page="perPage"
        class="my-0 mt-3"
        size="sm"
        align="right"
        v-if="totalRows > perPage"
      >
        <span class="text-info" slot="first-text">First</span>
        <span class="text-info" slot="prev-text">Prev</span>
        <span class="text-info" slot="next-text">Next</span>
        <span class="text-info" slot="last-text">Last</span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
    </div>

    <div v-else type="alert" class="alert alert-warning">{{noDataFound}}</div>

    <b-modal
      ref="modalMailTemplate"
      hide-footer
      id="modalMailTemplate"
      no-close-on-backdrop
      :title="modalTitle"
    >
      <MailTemplateForm :item="Object.assign({}, item)" @onCompleted="onCompleted" />
    </b-modal>
  </div>
</template>

<script>
import MailTemplateItem from "@/pages/components/mailTemplate/MailTemplateItem.vue";
import MailTemplateForm from "@/pages/components/mailTemplate/MailTemplateForm.vue";
import Breadcrumb from "@/pages/components/Breadcrumb.vue";
import API from "@/services/Api";
import Global from "@/services/Global";
import BaseComponent from "@/pages/components/BaseComponent";
import PageLoading from "@/pages/components/PageLoading.vue";
export default {
  extends: BaseComponent,
  components: {
    MailTemplateItem,
    MailTemplateForm,
    PageLoading,
    Breadcrumb
  },
  data() {
    return {
      loading: false,
      listItem: [],
      item: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 4,
      filterText: null,
      modalTitle: "Registro",
      breadcrumbListItem: [
        {
          title: "Configuraciones",
          route: "settings"
        }
      ]
    };
  },
  mounted() {
    this.fetchList();
    this.totalRows = this.listItem.length;
  },
  computed: {
    noDataFound() {
      return Global.noDataFound;
    },
    filterListItem() {
      return this.listItem.filter(item => {
        return (
          !this.filterText ||
          item.subject.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1
        );
      });
    }
  },
  methods: {
    fetchList() {
      this.loading = true;
      console.log("fetchList");
      API.get("MailTemplate/GetAllAsync")
        .then(response => {
          console.log("response", response);

          this.loading = false;
          this.listItem =
            response && response.data && response.data.result
              ? response.data.result
              : [];
          this.totalRows = this.listItem.length;
        })
        .catch(error => {
          this.loading = false;
          console.log("error", error);
        });
    },
    onNew() {
      this.item = {};
      this.modalTitle = "Nuevo registro";
      this.$refs["modalMailTemplate"].show();
    },
    onEdit(item) {
      this.item = item;
      this.modalTitle = "Editar registro";
      this.$refs["modalMailTemplate"].show();
    },

    onCompleted(evt) {
      this.item = {};
      if (evt) {
        this.fetchList();
      }
      this.$refs["modalMailTemplate"].hide();
    },
    onDelete(item) {
      this.axiosDelete({
        url: "MailTemplate/DeleteAsync",
        id: item.id
      }).then(() => {
        this.onCompleted(true);
      });
    },
    paginador(items) {
      const indiceInicio = (this.currentPage - 1) * this.perPage;
      const indiceFinal =
        indiceInicio + this.perPage > items.length
          ? items.length
          : indiceInicio + this.perPage;
      return items.slice(indiceInicio, indiceFinal);
    }
  }
};
</script>
