<template>
  <div class="dashboard">
    <div class="filter-box">
      <div class="row">
        <div class="col-12 col-md-5">
          <div class="d-flex">
            <div class="bd-datepicker">
              <i class="far fa-calendar-alt"></i>
              <datepicker v-model="from" name="startDate" class="mx-1"
                >gd</datepicker
              >
            </div>
            <div class="bd-datepicker mb-2">
              <i class="far fa-calendar-alt"></i>
              <datepicker v-model="to" name="fecha" class="mx-1"></datepicker>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-7">
          <div class="d-flex">
            <select
              class="custom-select mx-1"
              v-model="companyId"
              @change="filterDasboardBy(1)"
            >
              <option selected value>-- empresa --</option>
              <option
                v-for="(item, index) in companies"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <select
              class="custom-select mx-1"
              v-model="projectId"
              @change="filterDasboardBy(1)"
            >
              <option selected value>-- proyecto --</option>
              <option
                v-for="(item, index) in projects"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <select
              class="custom-select mx-1"
              v-model="agentId"
              @change="filterDasboardBy(1)"
            >
              <option selected value>-- agente --</option>
              <option
                v-for="(item, index) in users"
                :key="index"
                :value="item.id"
              >
                {{ item.firstName }} {{ item.lastName }}
              </option>
            </select>
            <select
              class="custom-select mx-1"
              v-model="statusId"
              @change="filterDasboardBy(1)"
            >
              <option selected value>-- estatus --</option>
              <option
                v-for="(item, index) in status"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
        <card type="chart" class="report-indicator">
          <DSpinner v-if="ri_loading" />
          <template slot="header" v-else>
            <h5 class="card-category hx-ellipsis">Tickets</h5>
            <h1 class="card-title">
              {{ reportIndicator ? reportIndicator.totalTickets : 0 }}
            </h1>
          </template>
        </card>
      </div>
      <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
        <card type="chart" class="report-indicator">
          <DSpinner v-if="ri_loading" />
          <template slot="header" v-else>
            <h5 class="card-category hx-ellipsis">Sin Atender</h5>
            <h1 class="card-title text-warning">
              {{ reportIndicator ? reportIndicator.notAttendedTickets : 0 }}
            </h1>
          </template>
        </card>
      </div>
      <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
        <card type="chart" class="report-indicator">
          <DSpinner v-if="ri_loading" />
          <template slot="header" v-else>
            <h5 class="card-category hx-ellipsis">Atendidos</h5>
            <h1 class="card-title text-success">
              {{ reportIndicator ? reportIndicator.attendedTickets : 0 }}
            </h1>
          </template>
        </card>
      </div>
      <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
        <card type="chart" class="report-indicator">
          <DSpinner v-if="ri_loading" />
          <template slot="header" v-else>
            <h5 class="card-category hx-ellipsis">Concluidos</h5>
            <h1 class="card-title text-info">
              {{ reportIndicator ? reportIndicator.concludedTickets : 0 }}
            </h1>
          </template>
        </card>
      </div>
      <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
        <card type="chart" class="report-indicator">
          <DSpinner v-if="ri_loading" />
          <template slot="header" v-else>
            <h5 class="card-category hx-ellipsis">Cancelados</h5>
            <h1 class="card-title text-warning">
              {{ reportIndicator ? reportIndicator.cancelledTickets : 0 }}
            </h1>
          </template>
        </card>
      </div>
      <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
        <card type="chart" class="report-indicator">
          <DSpinner v-if="ri_loading" />
          <template slot="header" v-else>
            <h5 class="card-category hx-ellipsis">Rechazados</h5>
            <h1 class="card-title text-warning">
              {{ reportIndicator ? reportIndicator.rejectedTickets : 0 }}
            </h1>
          </template>
        </card>
      </div>
    </div>

    <div class="row my-2">
      <div class="col-12">
        <card type="chart" class="report-all">
          <DSpinner v-if="tr_loading" :width="25" />

          <template slot="header">
            <div class="row">
              <div class="col-6">
                <h5 class="card-category">Tiempo resolución Tickets</h5>
              </div>
              <div class="col-6">
                <b-pagination
                  v-model="tRPage"
                  :total-rows="ticketResolutionTime.totalItems"
                  :per-page="tRPerPage"
                  class="my-0 mt-3"
                  size="sm"
                  align="right"
                  v-if="ticketResolutionTime.totalItems > tRPerPage"
                  @change="onTicketResolutionTimeReportChange($event)"
                >
                  <span class="text-info" slot="first-text">First</span>
                  <span class="text-info" slot="prev-text">Prev</span>
                  <span class="text-info" slot="next-text">Next</span>
                  <span class="text-info" slot="last-text">Last</span>
                  <span slot="page" slot-scope="{ page, active }">
                    <b v-if="active">{{ page }}</b>
                    <i v-else>{{ page }}</i>
                  </span>
                </b-pagination>
              </div>
            </div>
          </template>
          <div
            class="row"
            v-if="
              !tr_loading &&
              ticketResolutionTime &&
              ticketResolutionTime.resultItems &&
              ticketResolutionTime.resultItems.length > 0
            "
          >
            <div class="col-12 col-md-8">
              <div class="chart-area">
                <bar-chart
                  style="height: 100%"
                  chart-id="blue-bar-chart"
                  :chart-data="tRtickets.chartData"
                  :gradient-stops="tRtickets.gradientStops"
                  :extra-options="tRtickets.extraOptions"
                ></bar-chart>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="table-responsive">
                <table class="table tablesorter">
                  <thead class="text-primary">
                    <tr>
                      <th>Folio</th>
                      <th>Agente</th>
                      <th>Sistema</th>
                    </tr>
                  </thead>
                  <tbody class>
                    <tr
                      v-for="(item, index) in ticketResolutionTime.resultItems"
                      :key="index"
                    >
                      <td>{{ item.folio }}</td>
                      <td>{{ item.agentTime }}</td>
                      <td>{{ item.systemTime }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-else class="no-data-found text-warning">No hay registro</div>
        </card>
      </div>
    </div>

    <div class="row my-2">
      <div class="col-12">
        <card type="chart" class="report-all">
          <DSpinner v-if="trt_loading" :width="25" />
          <template slot="header">
            <div class="row">
              <div class="col-6">
                <h5 class="card-category">Tiempo resolución Tareas</h5>
              </div>
              <div class="col-6">
                <b-pagination
                  v-model="tRtPage"
                  :total-rows="taskResolutionTime.totalItems"
                  :per-page="tRtPerPage"
                  class="my-0 mt-3"
                  size="sm"
                  align="right"
                  v-if="taskResolutionTime.totalItems > tRtPerPage"
                  @change="onTaskResolutionTimeReportChange($event)"
                >
                  <span class="text-info" slot="first-text">First</span>
                  <span class="text-info" slot="prev-text">Prev</span>
                  <span class="text-info" slot="next-text">Next</span>
                  <span class="text-info" slot="last-text">Last</span>
                  <span slot="page" slot-scope="{ page, active }">
                    <b v-if="active">{{ page }}</b>
                    <i v-else>{{ page }}</i>
                  </span>
                </b-pagination>
              </div>
            </div>
          </template>
          <div
            class="row"
            v-if="
              !trt_loading &&
              taskResolutionTime &&
              taskResolutionTime.resultItems &&
              taskResolutionTime.resultItems.length > 0
            "
          >
            <div class="col-12 col-md-8">
              <div class="chart-area">
                <bar-chart
                  style="height: 100%"
                  chart-id="blue-bar-chart"
                  :chart-data="tRtasks.chartData"
                  :gradient-stops="tRtasks.gradientStops"
                  :extra-options="tRtasks.extraOptions"
                ></bar-chart>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="table-responsive">
                <table class="table tablesorter">
                  <thead class="text-primary">
                    <tr>
                      <th>Folio</th>
                      <th>Agente</th>
                      <th>Sistema</th>
                    </tr>
                  </thead>
                  <tbody class>
                    <tr
                      v-for="(item, index) in taskResolutionTime.resultItems"
                      :key="index"
                    >
                      <td>{{ item.folio }}</td>
                      <td>{{ item.agentTime }}</td>
                      <td>{{ item.systemTime }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-else class="no-data-found text-warning">No hay registro</div>
        </card>
      </div>
    </div>

    <div class="row my-2">
      <div class="col-12">
        <card type="chart" class="report-all">
          <DSpinner v-if="tt_loading" :width="25" />
          <template slot="header">
            <div class="row">
              <div class="col-6">
                <h5 class="card-category">Tareas X Ticket</h5>
              </div>
              <div class="col-6">
                <b-pagination
                  v-model="tCPage"
                  :total-rows="ticketTaskCountReport.totalItems"
                  :per-page="tCPerPage"
                  class="my-0 mt-3"
                  size="sm"
                  align="right"
                  v-if="ticketTaskCountReport.totalItems > tCPerPage"
                  @change="onTicketTaskCountReportChange($event)"
                >
                  <span class="text-info" slot="first-text">First</span>
                  <span class="text-info" slot="prev-text">Prev</span>
                  <span class="text-info" slot="next-text">Next</span>
                  <span class="text-info" slot="last-text">Last</span>
                  <span slot="page" slot-scope="{ page, active }">
                    <b v-if="active">{{ page }}</b>
                    <i v-else>{{ page }}</i>
                  </span>
                </b-pagination>
              </div>
            </div>
          </template>
          <div
            class="row"
            v-if="
              !tt_loading &&
              ticketTaskCountReport &&
              ticketTaskCountReport.resultItems &&
              ticketTaskCountReport.resultItems.length > 0
            "
          >
            <div class="col-12 col-md-8">
              <div class="chart-area">
                <bar-chart
                  style="height: 100%"
                  chart-id="blue-bar-chart"
                  :chart-data="taskCountList.chartData"
                  :gradient-stops="taskCountList.gradientStops"
                  :extra-options="taskCountList.extraOptions"
                ></bar-chart>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="table-responsive">
                <table class="table tablesorter">
                  <thead class="text-primary">
                    <tr>
                      <th>Folio</th>
                      <th>Tarea (s)</th>
                    </tr>
                  </thead>
                  <tbody class>
                    <tr
                      v-for="(item, index) in ticketTaskCountReport.resultItems"
                      :key="index"
                    >
                      <td>{{ item.folio }}</td>
                      <td>{{ item.taskCount }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div v-else class="no-data-found text-warning">No hay registro</div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/services/Api";
import BarChart from "@/components/Charts/BarChart";
import DSpinner from "@/components/DSpinner";
import * as chartConfigs from "@/components/Charts/config";
import TaskList from "./Dashboard/TaskList";
import config from "@/config";
import Datepicker from "vuejs-datepicker";
import * as dayjs from "dayjs";
export default {
  components: {
    BarChart,
    TaskList,
    Datepicker,
    DSpinner,
  },
  data() {
    return {
      users: [],
      status: [],
      companies: [],
      projects: [],
      from: "2019-07-28",
      to: "2019-10-28",
      companyId: "",
      projectId: "",
      agentId: "",
      statusId: "",
      pagex: 0,
      reportIndicator: {},
      tRtickets: {
        extraOptions: chartConfigs.tRticketsOptions,
        chartData: {
          labels: ["COUNTRY"],
          datasets: [
            {
              label: "Agente",
              borderColor: "#1d8cf8",
              backgroundColor: "#1d8cf8",
              data: [],
            },
            {
              label: "Sistema",
              borderColor: "#2dce89",
              backgroundColor: "#2dce89",
              data: [],
            },
          ],
        },

        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },
      tRtasks: {
        extraOptions: chartConfigs.tRtasksOptions,
        chartData: {
          labels: [],
          datasets: [
            {
              label: "Agente",
              borderColor: "#1d8cf8",
              backgroundColor: "#1d8cf8",
              data: [],
            },
            {
              label: "Sistema",
              borderColor: "#2dce89",
              backgroundColor: "#2dce89",
              data: [],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },
      taskCountList: {
        extraOptions: chartConfigs.barChartOptions1,
        chartData: {
          labels: [],
          datasets: [
            {
              label: "Tarea(s)",
              borderColor: "#1d8cf8",
              backgroundColor: "#1d8cf8",
              data: [],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },

      tRPage: 1,
      tRPerPage: 4,
      ticketResolutionTime: [],

      tRtPage: 1,
      tRtPerPage: 4,
      taskResolutionTime: [],

      tCPage: 1,
      tCPerPage: 4,
      ticketTaskCountReport: [],

      ri_loading: false,
      tr_loading: false,
      trt_loading: false,
      tt_loading: false,
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
  },
  methods: {
    filterDasboardBy(inter) {
      this.getReportIndicatorsAsync();
      this.getTicketResolutionTimeReportAsync(1);
      this.getTaskResolutionTimeReportAsync(1);
      this.getTicketTaskCountReportAsync(1);
    },
    getCompanies() {
      API.get("Company/GetAllAsync")
        .then((response) => {
          this.companies =
            response && response.data && response.data.result
              ? response.data.result
              : [];
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getStatus() {
      API.get("Status/GetByTypeAsync?statusType=2")
        .then((response) => {
          this.status = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getUserAgents() {
      API.get("User/GetAllAsync")
        .then((response) => {
          this.users = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getProjects() {
      API.get("Project/GetAllAsync")
        .then((response) => {
          this.projects = response.data.result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getReportIndicatorsAsync() {
      this.ri_loading = true;
      API.post("Report/GetReportIndicatorsAsync", {
        from: this.from || null,
        to: this.to || null,
        companyId: this.companyId || null,
        projectId: this.projectId || null,
        agentId: this.agentId || null,
        statusId: this.statusId || null,
      })
        .then((response) => {
          this.ri_loading = false;
          if (response && response.data && response.data.resultCode == 1) {
            this.reportIndicator = response.data.result;
          } else {
            if (response.data.resultMessages.length > 0) {
              const message = response.data.resultMessages.join(",<br />");
            }
          }
        })
        .catch((error) => {
          this.ri_loading = false;
          console.log("error", error);
        });
    },
    getTicketResolutionTimeReportAsync(page) {
      this.tr_loading = true;
      API.post("Report/GetTicketResolutionTimeReportAsync", {
        from: this.from || null,
        to: this.to || null,
        companyId: this.companyId || null,
        projectId: this.projectId || null,
        agentId: this.agentId || null,
        statusId: this.statusId || null,
        page: page,
        pageSize: this.tRPerPage,
      })
        .then((response) => {
          this.tr_loading = false;

          //console.log("GetTicketResolutionTimeReportAsync: ", response);
          if (response && response.data && response.data.resultCode == 1) {
            this.ticketResolutionTime = response.data.result;
            // console.log(
            //   "GetTicketResolutionTimeReportAsync",
            //   this.ticketResolutionTime
            // );
            this.fetchTRTicketsData(this.ticketResolutionTime.resultItems);
          } else {
            if (response.data.resultMessages.length > 0) {
              const message = response.data.resultMessages.join(",<br />");
              // console.log("GetTicketResolutionTimeReportAsync: ", message);
            }
          }
        })
        .catch((error) => {
          this.tr_loading = false;
          console.log("error", error);
        });
    },
    getTaskResolutionTimeReportAsync(page) {
      this.trt_loading = true;
      API.post("Report/GetTaskResolutionTimeReportAsync", {
        from: this.from || null,
        to: this.to || null,
        companyId: this.companyId || null,
        projectId: this.projectId || null,
        agentId: this.agentId || null,
        statusId: this.statusId || null,
        page: page,
        pageSize: this.tRtPerPage,
      })
        .then((response) => {
          this.trt_loading = false;
          if (response && response.data && response.data.resultCode == 1) {
            this.taskResolutionTime = response.data.result;
            // console.log(
            //   "GetTaskResolutionTimeReportAsync",
            //   this.taskResolutionTime
            // );
            this.fetchTRTasksData(this.taskResolutionTime.resultItems);
          } else {
            if (response.data.resultMessages.length > 0) {
              const message = response.data.resultMessages.join(",<br />");
              //console.log("getTaskResolutionTimeReportAsync", message);
            }
          }
        })
        .catch((error) => {
          this.trt_loading = false;
          console.log("error", error);
        });
    },
    getTicketTaskCountReportAsync(page) {
      this.tt_loading = true;
      API.post("Report/getTicketTaskCountReportAsync", {
        from: this.from,
        to: this.to,
        companyId: this.companyId || null,
        projectId: this.projectId || null,
        agentId: this.agentId || null,
        statusId: this.statusId || null,
        page: page,
        pageSize: this.tCPerPage,
      })
        .then((response) => {
          this.tt_loading = false;
          if (response && response.data && response.data.resultCode == 1) {
            this.ticketTaskCountReport = response.data.result;
            this.fetchTaskCountData(this.ticketTaskCountReport.resultItems);
          } else {
            if (response.data.resultMessages.length > 0) {
              const message = response.data.resultMessages.join(",<br />");
            }
          }
        })
        .catch((error) => {
          this.tt_loading = false;
          console.log("error", error);
        });
    },
    fetchTRTicketsData(_results) {
      let folio = _results.map((a) => a.folio);
      let systemTime = _results.map((a) => a.systemTime);
      let agentTime = _results.map((a) => a.agentTime);
      let systemTimeHours = _results.map((a) => a.systemTimeHours);
      let agentTimeHours = _results.map((a) => a.agentTimeHours);
      // console.log("_results", _results);
      const datasets = this.tRtickets.chartData.datasets;
      const chartData = {
        labels: folio,
        datasets: [
          {
            ...datasets[0],
            data: systemTimeHours,
            xdate: systemTime,
          },
          {
            ...datasets[1],
            data: agentTimeHours,
            xdate: agentTime,
          },
        ],
      };
      this.tRtickets.chartData = {
        ...chartData,
      };

      // console.log("this.tRtickets", this.tRtickets);
    },
    fetchTRTasksData(_results) {
      let folio = _results.map((a) => a.folio);
      let name = _results.map((a) => a.name);
      let systemTime = _results.map((a) => a.systemTime);
      let agentTime = _results.map((a) => a.agentTime);
      let systemTimeHours = _results.map((a) => a.systemTimeHours);
      let agentTimeHours = _results.map((a) => a.agentTimeHours);
      // console.log("_results", _results);
      const datasets = this.tRtasks.chartData.datasets;
      const chartData = {
        labels: name,
        datasets: [
          {
            ...datasets[0],
            data: agentTimeHours,
            xdate: agentTime,
          },
          {
            ...datasets[1],
            data: systemTimeHours,
            xdate: systemTime,
          },
        ],
      };
      this.tRtasks.chartData = {
        ...chartData,
      };

      // console.log("this.tRtasks", this.tRtasks);
    },
    fetchTaskCountData(_results) {
      let folio = _results.map((a) => a.folio);
      let taskCount = _results.map((a) => a.taskCount);

      const datasets = this.taskCountList.chartData.datasets;
      const chartData = {
        labels: folio,
        datasets: [
          {
            ...datasets[0],
            data: taskCount,
          },
        ],
      };
      this.taskCountList.chartData = {
        ...chartData,
      };
    },
    onTicketResolutionTimeReportChange(page) {
      this.getTicketResolutionTimeReportAsync(page);
    },
    onTaskResolutionTimeReportChange(page) {
      this.getTaskResolutionTimeReportAsync(page);
    },
    onTicketTaskCountReportChange(page) {
      this.getTicketTaskCountReportAsync(page);
    },
    setDateFilter() {
      this.to = dayjs().format();
      this.from = dayjs().set("month", -11).format();
    },
  },
  created() {
    this.setDateFilter();
    this.getReportIndicatorsAsync();
    this.getTicketResolutionTimeReportAsync(1);
    this.getTaskResolutionTimeReportAsync(1);
    this.getTicketTaskCountReportAsync(1);
  },
  mounted() {
    this.i18n = this.$i18n;
    this.getUserAgents();
    this.getStatus();
    this.getCompanies();
    this.getProjects();
  },
  beforeDestroy() {
    this.i18n.locale = "es";
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const menus = vm.$store.state.menus;
      vm.rule = menus.find((x) => x.pathName == to.name);
      if (!(vm.rule && vm.rule.canRead)) {
        vm.$router.push({ name: "mytickets" });
      }
    });
  },
};
</script>
<style lang="scss">
.dashboard {
  .filter-box {
    .b-date-xg {
      width: 150px;
    }
    input,
    select {
      height: 32px;
      border: 1px solid #cad1d7;
    }
    .bd-datepicker {
      position: relative;
      i {
        position: absolute;
        right: 10px;
        z-index: 2;
        top: 8px;
      }

      input {
        border-radius: 4px;
        color: #222a42;
        border: 1px solid;
        border-color: rgba(29, 37, 59, 0.5);
        height: 32px;
        display: block;
        width: 100%;

        padding: 0.5rem 0.7rem;
        font-size: 0.875rem;
        line-height: 1.42857;

        background-color: transparent;
        background-clip: padding-box;
        border: 1px solid #cad1d7;
        border-radius: 0.25rem;
        box-shadow: none;
        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
    }
  }
}
.table-responsive {
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 10px;
  max-height: 214px;
}
.report-indicator {
  min-height: 80px;

  .card-body {
    padding: 0;
  }
}
.report-all {
  min-height: 309px;

  .card-body {
    padding: 0;
  }
}
.no-data-found {
  min-height: 80%;
  position: absolute;
  width: 100%;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  display: flex;
}
.hx-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

