<template>
  <form class="mt">
    <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{errorMessage}}</b-alert>
    <!-- <pre>{{item}}</pre> -->
    <!-- <div class="form-group" v-if="item && item.id">
      <label for="id">ID: {{item.id}}</label>
    </div>-->

    <div class="form-group">
      <label class="small mb-0 text-default">Título</label>
      <h6 class="text-muted">{{item.name}}</h6>
    </div>

    <div class="form-group">
      <label class="small mb-0 text-default">Descripción</label>
      <h6 class="text-muted">{{item.description}}</h6>
    </div>

    <div class="form-group">
      <label class="small mb-0 text-default">Estatus</label>
      <h6 class="text-muted">
        <span class="badge badge-info">{{taskStatusName()}}</span>
      </h6>
    </div>

    <div
      class="form-group"
      v-if="((user && user.roleName == 'Agente') && validateStatus({statusId: item.taskStatusId, name: 'En proceso'}))"
    >
      <label class="small mb-0 text-default">Tiempo de resolución</label>
      <div class="d-flex">
        <div class="form-group text-center">
          <label for="effortTimehh" class="small mb-0">HH</label>
          <input
            type="number"
            class="form-control n-control-w"
            placeholder="HH"
            v-model="effortTime.hh"
            name="effortTimehh"
            id="effortTimehh"
            min="0"
          />
        </div>
        <div class="form-group text-center">
          <label for="effortTimemm" class="small mb-0">mm</label>
          <input
            type="number"
            class="form-control n-control-w"
            placeholder="mm"
            v-model="effortTime.mm"
            name="effortTimemm"
            id="effortTimemm"
            min="0"
          />
        </div>
        <div class="form-group text-center">
          <label for="effortTimess" class="small mb-0">ss</label>
          <input
            type="number"
            class="form-control n-control-w"
            placeholder="ss"
            v-model="effortTime.ss"
            name="effortTimess"
            id="effortTimess"
            min="0"
          />
        </div>
      </div>
    </div>
    <div class="form-group" v-else>
      <label class="small mb-0 text-default">Tiempo de resolución</label>
      <h6 class="text-muted">{{item.systemEffortTime }}</h6>
    </div>

    <div
      class="form-group"
      v-if="(user && user.roleName == 'Administrador') && (validateStatus({statusId: item.taskStatusId, name: 'Generada'}) || validateStatus({statusId: item.taskStatusId, name: 'Pendiente'}))"
    >
      <label for="priorityId" class="mb-0 mt-1">
        <small class="text-muted">Asignado a</small>
      </label>
      <select
        class="custom-select"
        id="assignedUserId"
        name="assignedUserId"
        v-validate="'required'"
        v-model="item.assignedUserId"
      >
        <option>seleccione usuario para asignar</option>
        <option
          v-for="(item, index) in assignedUsers"
          :key="index"
          :value="item.id"
        >{{item.firstName}} {{item.lastName}}</option>
      </select>
    </div>
    <div class="form-group" v-else>
      <label class="small mb-0 text-default">Asignado</label>
      <h6 class="text-muted">{{ getUserName ()}}</h6>
    </div>

    <div class="clearfix">
      <div class="btn-toolbar float-right pb-4">
        <base-button
          v-if="validateStatus({statusId: item.taskStatusId, name: 'Generada'})"
          native-type="button"
          type="primary"
          size="sm"
          :loading="ast_loading"
          @click="assignTask"
        >Asignar</base-button>

        <base-button
          v-if="(user && user.roleName == 'Agente') && (validateStatus({statusId: item.taskStatusId, name: 'Asignada'}) || validateStatus({statusId: item.taskStatusId, name: 'Pendiente'}))"
          native-type="button"
          type="primary"
          size="sm"
          :loading="att_loading"
          @click="attendTask"
        >Atender</base-button>

        <base-button
          v-if="(user && user.roleName == 'Agente') &&  validateStatus({statusId: item.taskStatusId, name: 'En proceso'})"
          native-type="button"
          type="primary"
          size="sm"
          :loading="pst_loading"
          @click="pauseTask"
        >Pausar</base-button>

        <base-button
          v-if="(user && user.roleName == 'Agente') &&  validateStatus({statusId: item.taskStatusId, name: 'En proceso'})"
          native-type="button"
          type="primary"
          size="sm"
          :loading="cct_loading"
          @click="concludeTask"
        >Concluir</base-button>

        <base-button
          v-if="(user && user.roleName == 'Administrador') && validateStatus({statusId: item.taskStatusId, name: 'Terminada'})"
          native-type="button"
          type="primary"
          size="sm"
          :loading="rvt_loading"
          @click="reviewTask"
        >Revisar</base-button>

        <base-button
          v-if="validateCancelStatus(item.taskStatusId)"
          native-type="button"
          type="primary"
          size="sm"
          :loading="cant_loading"
          @click="cancelTask"
        >Cancelar</base-button>

        <base-button
          v-if="validateResetStatus()"
          native-type="button"
          type="primary"
          size="sm"
          :loading="reset_loading"
          @click="resetTask"
        >Reiniciar</base-button>
      </div>
    </div>
  </form>
</template>

<script>
import BaseComponent from "@/pages/components/BaseComponent";
import Datepicker from "vuejs-datepicker";
import { es } from "vuejs-datepicker/dist/locale";
import API from "@/services/Api";
import Global from "@/services/Global";
export default {
  extends: BaseComponent,
  components: {
    Datepicker
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    status: {
      type: Array
    },
    ticketStatus: {
      type: String
    }
  },
  data() {
    return {
      es: es,
      errorMessage: "",
      assignedUsers: [],
      effortTime: {
        hh: 0,
        mm: 0,
        ss: 0
      },
      user: null,
      ticketId: 0,
      ast_loading: false,
      att_loading: false,
      pst_loading: false,
      cct_loading: false,
      rvt_loading: false,
      cant_loading: false,
      reset_loading: false
    };
  },
  created() {
    this.getUserData();
  },
  mounted() {
    this.ticketId = this.$route.params.id;
    this.getAssignedUsers();
  },
  computed: {
    isFormValid() {
      return !Object.keys(this.veeFields).some(
        key => this.veeFields[key].invalid
      );
    }
  },
  methods: {
    validateStatus(value) {
      const status = this.status.find(x => x.name == value.name);
      return status && status.id == value.statusId;
    },
    validateCancelStatus(statusId) {
      const statuses = this.status.filter(
        x =>
          (x.name == "Revisada" ||
            x.name == "Terminada" ||
            x.name == "Cancelada") &&
          x.id == statusId
      );

      return !(statuses && statuses.length && statuses.length > 0);
    },
    validateResetStatus() {
      return this.ticketStatus == "No resuelto";
    },
    getAssignedUsers() {
      API.get("User/GetAllAsync")
        .then(response => {
          this.assignedUsers = response.data.result;
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getUserName() {
      var name = "";
      if (
        this.item &&
        this.item.taskStatusId &&
        this.assignedUsers &&
        this.assignedUsers.length
      ) {
        const user = this.assignedUsers.find(
          x => x.id == this.item.assignedUserId
        );
        name = user ? `${user.firstName} ${user.lastName}` : "N/A";
      }
      return name;
    },
    taskStatusName() {
      var taskStatus = "";
      if (this.item && this.item.taskStatusId) {
        const status = this.status.find(x => x.id == this.item.taskStatusId);
        taskStatus = status ? status.name : "";
      }
      return taskStatus;
    },
    assignTask() {
      this.$validator.validate().then(valid => {
        if (valid) {
          // console.log("task", this.item);
          var task = {
            ...this.item,
            ticketId: this.ticketId
          };
          if (task.id) {
            this.ast_loading = true;
            API.post("Task/AssignTask", task)
              .then(response => {
                this.ast_loading = false;
                this.handleResponse(response, true);
              })
              .catch(error => {
                this.ast_loading = false;
                this.handleFaltalError;
              });
          }
        }
      });
    },
    attendTask() {
      this.$validator.validate().then(valid => {
        if (valid) {
          var task = {
            ...this.item,
            ticketId: this.ticketId
          };
          if (task.id) {
            this.att_loading = true;
            API.post("Task/AttendTask", task)
              .then(response => {
                this.att_loading = false;
                this.handleResponse(response, true);
              })
              .catch(error => {
                this.att_loading = false;
                this.handleFaltalError;
              });
          }
        }
      });
    },
    pauseTask() {
      this.$validator.validate().then(valid => {
        if (valid) {
          var task = {
            ...this.item,
            ticketId: this.ticketId
          };
          if (task.id) {
            this.pst_loading = true;
            API.post("Task/PauseTask", task)
              .then(response => {
                this.pst_loading = false;
                this.handleResponse(response, true);
              })
              .catch(error => {
                this.pst_loading = false;
                this.handleFaltalError;
              });
          }
        }
      });
    },
    concludeTask() {
      // console.log(this.item);
      this.$validator.validate().then(valid => {
        if (valid) {
          if (this.validateEffortTime() == 0) {
            this.handleNotify(
              "Favor digite el tiempo de conclusión",
              "warning"
            );
            return;
          }

          var task = {
            ...this.item,
            ticketId: this.ticketId,
            effortTime: `${this.effortTime.hh}:${this.effortTime.mm}:${this.effortTime.ss}`
          };

          if (task.id) {
            this.cct_loading = true;
            API.post("Task/ConcludeTask", task)
              .then(response => {
                this.cct_loading = false;
                this.handleResponse(response, true);
              })
              .catch(error => {
                this.cct_loading = false;
                this.handleFaltalError;
              });
          }
        }
      });
    },
    reviewTask() {
      this.$validator.validate().then(valid => {
        if (valid) {
          var task = {
            ...this.item,
            ticketId: this.ticketId
          };
          if (task.id) {
            this.rvt_loading = true;
            API.post("Task/ReviewTask", task)
              .then(response => {
                this.rvt_loading = false;
                this.handleResponse(response, true);
              })
              .catch(error => {
                this.rvt_loading = false;
                this.handleFaltalError;
              });
          }
        }
      });
    },
    cancelTask() {
      this.$validator.validate().then(valid => {
        if (valid) {
          var task = {
            ...this.item,
            ticketId: this.ticketId
          };
          if (task.id) {
            this.$swal({
              text: Global.concelConfirmMessage,
              buttons: ["No", "Si"],
              dangerMode: true
            }).then(willCancel => {
              if (willCancel) {
                this.cant_loading = true;
                API.post("Task/CancelTask", task)
                  .then(response => {
                    this.cant_loading = false;
                    this.handleResponse(response, true);
                  })
                  .catch(error => {
                    this.cant_loading = false;
                    this.handleFaltalError;
                  });
              }
            });
          }
        }
      });
    },
    resetTask() {
      this.$validator.validate().then(valid => {
        if (valid) {
          var task = {
            ...this.item,
            ticketId: this.ticketId
          };
          if (task.id) {
            this.reset_loading = true;
            API.post("Task/ResetTask", task)
              .then(response => {
                this.reset_loading = false;
                this.handleResponse(response, true);
              })
              .catch(error => {
                this.reset_loading = false;
                this.handleFaltalError;
              });
          }
        }
      });
    },
    getUserData() {
      var userData = localStorage.getItem("user_data");
      if (userData) {
        this.user = JSON.parse(userData);
        //console.log(this.user);
      }
    },
    validateEffortTime() {
      if (
        !this.effortTime ||
        (this.effortTime.hh == 0 &&
          this.effortTime.mm == 0 &&
          this.effortTime.ss == 0)
      ) {
        return 0;
      }

      return 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.n-control-w {
  width: 60px;
  padding: 10px;
}
</style>
