<template>
  <form class="mt" @submit.prevent="onSubmit">
    <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{errorMessage}}</b-alert>
    <div class="form-group" v-if="item && item.id">
      <label for="id">ID: {{item.id}}</label>
    </div>
    <div class="form-group">
      <label for="name">Proyecto</label>
      <input
        class="form-control"
        type="text"
        name="name"
        id="name"
        placeholder="Proyecto"
        v-validate="'required'"
        v-model="item.name"
        autocomplete="off"
      />
    </div>
    <div class="form-group">
      <label for="companyId">Empresa</label>
      <select
        class="custom-select"
        name="companyId"
        id="companyId"
        v-validate="'required'"
        v-model="item.companyId"
      >
        <option v-for="(item, index) in companies" :key="index" :value="item.id">{{item.name}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="description">Descripción</label>
      <textarea
        name="description"
        id="description"
        rows="5"
        class="form-control"
        placeholder="Descripción"
        v-validate="'required'"
        v-model="item.description"
      ></textarea>
    </div>
    <div class="clearfix">
      <div class="btn-toolbar float-right pb-4">
        <base-button
          native-type="submit"
          type="primary"
          class="mb-3"
          size="sm"
          block
          :loading="s_loading"
        >Guardar</base-button>
      </div>
    </div>
  </form>
</template>

<script>
import BaseComponent from "@/pages/components/BaseComponent";
import API from "@/services/Api";
export default {
  extends: BaseComponent,
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      errorMessage: "",
      companies: []
    };
  },
  mounted() {
    this.getCompanies();
  },
  computed: {
    isFormValid() {
      return !Object.keys(this.veeFields).some(
        key => this.veeFields[key].invalid
      );
    }
  },
  methods: {
    getCompanies() {
      API.get("Company/GetAllAsync")
        .then(response => {
          this.companies = response.data.result;
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    onSubmit() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.s_loading = true;
          if (!this.item.id) {
            this.axioPost({
              url: "Project/AddAsync",
              value: this.item
            });
          } else {
            this.axioPut({
              url: "Project/UpdateAsync",
              value: this.item
            });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
