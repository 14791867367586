<template>
  <form @submit.prevent="register">
    <base-input
      v-validate="'required'"
      :class="{ 'is-valid': getError('firstName') }"
      v-model="item.firstName"
      name="firstName"
      placeholder="Nombre"
      addon-left-icon="tim-icons icon-single-02"
      autocomplete="off"
    ></base-input>

    <base-input
      v-validate="'required'"
      :class="{ 'is-valid': getError('lastName') }"
      v-model="item.lastName"
      name="lastName"
      placeholder="Apellido"
      addon-left-icon="tim-icons icon-single-02"
      autocomplete="off"
    ></base-input>

    <base-input
      :disabled="isEdit"
      v-validate="'required|email'"
      :class="{ 'is-valid': getError('email') }"
      v-model="item.email"
      name="email"
      placeholder="Email"
      addon-left-icon="tim-icons icon-email-85"
      autocomplete="off"
      class="bg-white"
    ></base-input>

    <div class="form-group">
      <select
        :disabled="isEdit"
        class="custom-select"
        :class="{ 'is-valid': getError('roleId') }"
        name="roleId"
        id="roleId"
        v-validate="'required|min:1'"
        v-model="item.roleId"
        @change="onRoleChange($event.target.value)"
      >
        <option value="0">Seleccione el rol</option>
        <option v-for="(item, index) in roles" :key="index" :value="item.id">
          {{ item.name }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <select
        class="custom-select"
        :class="{ 'is-valid': getError('companyId') }"
        name="companyId"
        id="companyId"
        v-validate="'required|min:1'"
        v-model="item.companyId"
        @change="onCompanyChange($event.target.value)"
      >
        <option value="0">Seleccione la empresa</option>
        <option
          v-for="(item, index) in filterCompanies"
          :key="index"
          :value="item.id"
        >
          {{ item.name }}
        </option>
      </select>
    </div>

    <div class="form-group" v-if="isGrupoPerTI">
      <select
        class="custom-select"
        name="departmentId"
        id="departmentId"
        v-model="item.departmentId"
      >
        <option value="0">Seleccione el area</option>
        <option
          v-for="(item, index) in departments"
          :key="index"
          :value="item.id"
        >
          {{ item.name }}
        </option>
      </select>
    </div>
    <!-- <h2>{{item.roleId}}</h2> -->
    <!-- <h2>{{roleId}}</h2>
    <h2>{{item.roleId}}</h2>-->
    <!-- <base-checkbox class="text-left" v-model="tems" readonly>
      Acepto los
      <a href="javascript:void(0)">terminos y condiciones</a>.
    </base-checkbox>-->

    <div class="clearfix">
      <base-button
          native-type="button"
          type="secondary"
          class="mb-3"
          size="sm"
          v-if="!item.emailConfirmed"
          @click="resendConfirmEmail(item.email)"
          :loading="s_loading"
          >Re-Enviar Email de confirmación</base-button
        >
      <div class="btn-toolbar float-right pb-4">
        
        <base-button
          native-type="submit"
          type="primary"
          class="mb-3"
          size="sm"
           
          :loading="s_loading"
          >Guardar</base-button
        >
      </div>
    </div>
  </form>
</template>

<script>
import API from "@/services/Api";
import BaseComponent from "@/pages/components/BaseComponent";
export default {
  extends: BaseComponent,
  props: {
    item: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tems: true,
      roles: [],
      companies: [],
      departments: [],
      loading: false,
      roleId: 0,
    };
  },
  mounted() {
    this.getRoles();
    this.getCompanies();
    this.getDepartments();
  },
  computed: {
    isFormValid() {
      return !Object.keys(this.veeFields).some(
        (key) => this.veeFields[key].invalid
      );
    },
    filterCompanies() {
      var roleId = this.item && this.item.roleId ? this.item.roleId : 0;
      if (roleId == 1 || roleId == 2) {
        return this.companies.filter((item) => item.id == 1);
      }
      return this.companies;
    },
    isGrupoPerTI() {
      const isGrupoPerti = this.item && this.item.companyId === 1;
      return isGrupoPerti;
    },
  },
  methods: {
    getError(fieldName) {
      return !this.errors.first(fieldName) == "";
    },
    register() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (!(this.item && this.item.roleId && this.item.roleId > 0)) {
            this.handleNotify("El campo rol es obligatorio", "warning");
            return;
          }
          if (!(this.item && this.item.companyId && this.item.companyId > 0)) {
            this.handleNotify("El campo empresa es obligatorio", "warning");
            return;
          }
          if (
            this.isGrupoPerTI &&
            !(this.item && this.item.departmentId && this.item.departmentId > 0)
          ) {
            this.handleNotify("El campo área es obligatorio", "warning");
            return;
          }

          this.s_loading = true;
          this.item.userDepartments = [this.item.departmentId];
          // console.log("User", this.item);
          if (!this.item.id) {
            this.axioPost({
              url: "Account/Register",
              value: this.item,
            });
          } else {
            this.axioPut({
              url: "User/UpdateAsync",
              value: this.item,
            });
          }
        }
      });
    },
    getRoles() {
      API.get("Role/GetAllAsync")
        .then((response) => {
          this.roles =
            response && response.data && response.data.result
              ? response.data.result
              : [];

          // console.log("this.roles", this.roles);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getCompanies() {
      API.get("Company/GetAllAsync")
        .then((response) => {
          this.companies =
            response && response.data && response.data.result
              ? response.data.result
              : [];
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getDepartments() {
      API.get("Department/GetAllAsync")
        .then((response) => {
          this.departments =
            response && response.data && response.data.result
              ? response.data.result
              : [];

          // console.log("this.roles", this.roles);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    onRoleChange(value) {
      this.item.companyId = 0;
      this.item.departmentId = 0;
      //this.isGPClientRol = false;
    },
    onCompanyChange(value) {
      //this.item.departmentId = value === 1 ? value : 0;
      this.item.departmentId = 0;
    },
    setRole() {
      if (this.item && this.item.roles && this.item.roles.length > 0) {
        return this.item.roles[0];
      } else {
        return 0;
      }
    },
    resendConfirmEmail(email){
      this.axioPost({
              url: "Account/ResendConfirmEmail",
              value: { email },
            });
    }
  },
};
</script>

