<template>
  <div class="auth-inner-page">
    <div class="container">
      <div class="col-lg-5 col-md-6 ml-auto mr-auto">
        <div class="text-center">
          <img class="logo" :src="'/gpassist/img/logo-perti.png'" alt />
        </div>
        <form @submit.prevent="register">
          <card class="card-register card-white">
            <template slot="header">
              <h3 class="card-title">Finaliza registro</h3>
            </template>

            <div class="form-group">
              <label for>Nombre y apellido</label>
              <p>{{item.firstName}} {{item.lastName}}</p>
            </div>

            <div class="form-group">
              <label for>Email</label>
              <p>{{item.email}}</p>
            </div>

            <base-input
              v-validate="'required|min:5'"
              name="password"
              :class="{'is-valid': getError('password')}"
              v-model="item.password"
              type="password"
              placeholder="Contraseña"
              addon-left-icon="tim-icons icon-lock-circle"
            ></base-input>

            <base-input
              v-validate="'required|min:5'"
              name="confirmPassword"
              :class="{'is-valid': getError('confirmPassword')}"
              v-model="item.confirmPassword"
              type="password"
              placeholder="Confirmar contraseña"
              addon-left-icon="tim-icons icon-lock-circle"
            ></base-input>
            <base-checkbox class="text-left" v-model="item.tems" readonly>
              Acepto los
              <a href="javascript:void(0)">terminos y condiciones</a>.
            </base-checkbox>

            <base-button
              native-type="submit"
              slot="footer"
              type="primary"
              class="mt-4"
              round
              block
              :loading="s_loading"
            >Finalizar registro</base-button>
          </card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/Api";
import NProgress from "nprogress";
import BaseComponent from "@/pages/components/BaseComponent";
export default {
  extends: BaseComponent,
  data() {
    return {
      token: "",
      item: {
        password: "",
        confirmPassword: "",
        confirmAccounToken: "",
        firstName: "",
        lastName: "",
        companyId: 0,
        roleId: 0,
        email: ""
      }
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    this.getRoles();
    this.getCompanies();
  },
  methods: {
    getError(fieldName) {
      return !this.errors.first(fieldName) == "";
    },
    register() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.s_loading = true;
          this.item.confirmAccounToken = this.token;
          API.post("Account/CompleteRegistration", this.item)
            .then(response => {
              this.s_loading = false;
              if (response && response.data) {
                let messageType = "success";
                const errors = response.data.resultMessages;
                if (response.data.resultCode !== 1) {
                  messageType = "danger";
                }
                if (errors && errors.length > 0) {
                  const message = errors.join(",<br />");
                  this.handleNotify(message, messageType);
                  this.$router.push({ name: "login" });
                }
              } else {
                this.handleNotify(Global.fatalErrorMessage, "warning");
              }
            })
            .catch(error => {
              this.handleFaltalError(error);
            });
        }
      });
    },
    getRoles() {
      API.get("Role/GetAllAsync")
        .then(response => {
          this.roles =
            response && response.data && response.data.result
              ? response.data.result
              : [];
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getCompanies() {
      API.get("Company/GetAllAsync")
        .then(response => {
          this.companies =
            response && response.data && response.data.result
              ? response.data.result
              : [];
        })
        .catch(error => {
          console.log("error", error);
        });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (Object.keys(to.query).length !== 0) {
      next(vm => {
        const token = to.query.token;
        // console.log(token);
        // console.log(encodeURI(token));
        NProgress.start();
        API.get(`Account/GetRegisterAccountDataByTokenAsync?token=${token}`)
          .then(response => {
            NProgress.done();
            vm.item = response.data.result;
            // console.log("this.response", response.data);
          })
          .catch(error => {
            this.loading = false;
            console.log("error", error);
          });
      });
    }
    next();
  }
};
</script>
<style lang="scss" src="./auth.scss"></style>
