<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <!-- v-if="rule && rule.canRead" -->
      <li class="breadcrumb-item" v-if="rule && rule.canRead">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <template v-if="listItem && listItem.length && listItem.length > 0">
        <li class="breadcrumb-item" v-for="(item, index) in listItem" :key="index">
          <router-link :to="{name: item.route}">{{item.title}}</router-link>
        </li>
      </template>
      <li class="breadcrumb-item active" aria-current="page">
        <small>{{title}}</small>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  props: {
    title: { type: String },
    listItem: {
      type: Array
    },
    showDashboard: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      user: null
    };
  },
  computed: {
    rule() {
      return this.$store.state.dashboardRule;
    }
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  padding: 0;
  background-color: transparent;
  margin: 0;
  height: 34px;
}
.breadcrumb-item {
  display: flex;
  align-items: center;
  &.active {
    color: #6c757d;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  font-size: 0.6rem;
  color: #6c757d;
  content: "/";
}
</style>
